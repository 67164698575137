// OrderSummary.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from './../../utils';
import './order-summary.css';
const OrderSummary = ({ order }) => {
  const { t } = useTranslation();
  useEffect(() => {
  }, []);
  return (
    <div id="order-summary" className='' >
      <div className='row small'>
        <div className='col-md-3 col-sm-6'>
          <p className='mb-1'><strong>Commande :</strong></p>
          <table>
            <tbody>
              <tr>
                <td className="pe-3">Nombre d'article{order.items.length > 1 ? 's' : ''}</td>
                <td className='ps-3 text-end'>{order.items.length}</td>
              </tr>
              <tr>
                <td className="pe-3">{t("totalPriceET")} </td>
                <td className='ps-3 text-end'>{formatPrice(order.totalPriceET)} EUR</td>
              </tr>
              <tr>
                <td className="pe-3">{t("orderVATAmount")} </td>
                <td className='ps-3 text-end'>{formatPrice(order.orderVATAmount)} EUR</td>
              </tr>
              <tr>
                <td className="pe-3">{t("totalPriceIT")} </td>
                <td className="ps-3 text-end">{formatPrice(order.totalPriceIT)} EUR</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-md-3 col-sm-6'>
          <p className='mb-1'><strong>Adresse de livraion :</strong></p>
          {order.delivery.daCompName != '' && <>{order.delivery.daCompName}<br /></>}
          {order.delivery.daName} {order.delivery.daFirstname}<br />
          {order.delivery.daAddress}<br />
          {order.delivery.daPostCode} {order.delivery.daCity}<br />
          {order.delivery.daCountry}<br />
          {order.delivery.daEmail}<br />
          {order.delivery.daPhone1} <br />
          {order.delivery.daPhone2}
        </div>
      </div>
    </div >
  );
};
export default OrderSummary;
