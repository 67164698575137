import { useState, useEffect } from 'react';
import { IoOpenOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import API from './../../api';
import { FaArrowRight } from 'react-icons/fa';
import Picture from '../helpers/Picture/Picture';

const FeaturedProducts = ({ onSelectProduct }) => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    fetchFeaturedProducts();
  }, []);

  const fetchFeaturedProducts = async () => {
    try {
      API.handleError = false;
      const response = await API.get(`/products/featured`);
      setFeaturedProducts(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div id="featured-products">
      <h2 className="bg-green py-2 px-3" onClick={fetchFeaturedProducts}>Kits & accessoires</h2>
      <div className="products">
        <div className="row pt-2">
          {featuredProducts.map(product => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 pointer mb-4" key={product.prodId} onClick={() => onSelectProduct(product)}>
              <div className="card">
                <div className="card-body p-0">
                  <div className="picture">
                    {product.prodPicture ? (
                      <Picture
                        src={product.prodPicture}
                        alt={product.prodTitre || "Product Image"}
                        className="card-img-top mb-2"
                      />
                    ) : (
                      <img src="/img/no-picture.png" alt="No Image" />
                    )}
                    <div className="position-absolute w-100 d-flex justify-content-between py-1 px-2" style={{ top: '0', left: '0', zIndex: 1000 }}>
                      <div className="bg-gray-e fs-8">Réf : {product.prodRef}</div>
                      <IoOpenOutline className="text-green pointer" />
                    </div>
                  </div>
                  <div className="card-text py-3 p-1 text-center fs-8">
                    {product.prodTitre.trim() !== '' ? product.prodTitre :
                      product.prodMemo.length > 50
                        ? `${product.prodMemo.substring(0, 50)}...`
                        : product.prodMemo}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {featuredProducts.length > 0 && (
            <p className="text-center">

              <Link className="btn btn-sm btn-green" to="/catalog?cat=Kit">
                <FaArrowRight className="me-2" />
                Tous les kits
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
