import React, { useState, useEffect } from 'react';
import API from './../../api';
import { useTranslation } from 'react-i18next';
import { debug, swalAlert, download } from './../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import DeliverySelect from './../deliveries/DeliverySelect';
import { CiViewList } from "react-icons/ci";
import OrderSummary from './OrderSummary';
import { loadCart } from '../../redux/cartSlice';

const OrderFinalize = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const [order, setOrder] = useState({
    orderId: 'current',
    orderDeliveryId: '',
    delivery: null,
    items: [],
    totalPriceET: 0,
    orderVATAmount: 0,
    totalPriceIT: 0,
    orderInternalRef: '',
  });
  useEffect(() => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      items: [...cart.items],
      totalPriceET: cart.totalPriceET,
      orderVATAmount: cart.orderVATAmount,
      totalPriceIT: cart.totalPriceIT,
    }));
  }, [cart]);
  const handleDeliverySelect = (delivery) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      delivery,
      orderDeliveryId: delivery.daId
    }));
  };
  const handleSave = async () => {
    try {
      API.handleError = false;
      const response = await API.post('/order', order);
      setOrder({ ...response.data })
      // await swalAlert('');
      dispatch(loadCart()); // entraîne un retour à la home car le panier est vide
    } catch (error) {
      swalAlert(error.response?.data?.error?.message || 'Une erreur est survenue.');
      console.log(error);
    }
  };
  return (
    <div>
      <div className="page-title">
        <h1>{
          order.orderId === 'current' ?
            order.orderDeliveryId === '' ? 'Adresse de livraison' : t('Summary')
            :
            'Commande validée'
        }</h1>
      </div>
      {cart && (
        <div className="container">
          {order.orderDeliveryId === '' ? (
            <DeliverySelect onSelect={handleDeliverySelect} />
          ) : (
            <div className="pt-5">
              {order.orderId === 'current' ? (
                <>
                  <OrderSummary order={order} />
                  <p
                    onClick={() =>
                      setOrder((prevOrder) => ({ ...prevOrder, delivery: null }))
                    }
                    className="text-green pointer fs-8"
                  >
                    Modifier l'adresse de livraison
                  </p>
                  <div className="form-group mb-2 submit">
                    <div className="row mb-3 small">
                      <div className="col-md-6">
                        <div className="form-group orderInternalRef">
                          <p className="mb-1">
                            <strong>Vous pouvez préciser une référence interne pour cette commande :</strong>
                          </p>
                          <input
                            type="text"
                            value={order.orderInternalRef}
                            name="orderInternalRef"
                            onChange={(e) =>
                              setOrder((prevOrder) => ({
                                ...prevOrder,
                                [e.target.name]: e.target.value,
                              }))
                            }
                            className="form-control orderInternalRef"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-green btn-lg btn-submit"
                      disabled={!order.delivery}
                      onClick={handleSave}
                    >
                      Valider et envoyer ma commande
                    </button>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <p className="mb-5 fw-bold">Votre commande est confirmée.</p>
                  <p>
                    <a className="btn btn-sm btn-green" onClick={e => download(`/order/pdf/${order.orderId}`)}>
                      <CiViewList className="pe-2 position-relative" size={34} style={{ top: '-2px' }} />Télécharger le bon de commande</a>
                  </p>
                  <p>
                    <Link to="/">Accueil</Link>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {debug(order, false)}
    </div>
  );
};
export default OrderFinalize;
