// Header.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header_User from './../user/Header_User';
import Header_Cart from './../cart/Header_Cart';
import { Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineShoppingCart, AiOutlineQuestionCircle, AiOutlineHome, AiOutlineMenu } from 'react-icons/ai'; // Import de l'icône Home

import { BiBook } from 'react-icons/bi';


import './header.css';
const Header = React.memo(() => {
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const { t } = useTranslation();


  const navLinks = [
    {
      to: "/",
      icon: <AiOutlineHome size={30} title="Accueil" />,
      label: "Accueil"
    },
    {
      to: "/catalog",
      icon: <BiBook size={30} title="Catalogue" />,
      label: "Catalogue"
    },
    {
      to: "/orders",
      icon: <AiOutlineShoppingCart size={30} title="Accéder aux commandes" />,
      label: "Commandes"
    },
    {
      to: "/faq",
      icon: <AiOutlineQuestionCircle size={30} title="Accéder à la FAQ" />,
      label: "FAQ/Forum"
    }
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <header className={`App-header ${menuOpen ? 'responsive-menu-on' : ''}`} id="app-header">
      <div id="header-main">
        <div className='' id='header-main-left'>
          {user.isSignedIn && (
            <Link className='logo' to="/">
              <img src='/img/logo.png' alt='amd jaeger' />
            </Link>
          )}
        </div>
        {user.isSignedIn ? (
          <div className='' id='header-main-center'>
            <nav className={`nav-links ${menuOpen ? 'active' : ''}`}>
              {navLinks.map((link, index) => (
                <Link to={link.to} className="btn btn-green" key={index}>
                  {link.icon}
                  <span>{link.label}</span>
                </Link>
              ))}
            </nav>
          </div>
        ) : (
          <div className="text-center">
            <span className="logo">
              <img src='/img/logo.png' alt='amd jaeger' />
            </span>
          </div>
        )}
        <div className='' id='header-main-right'>
          <div className='fw-bold mb-1 fs-6 text-gray-9'>{config.phone_number}</div>
          <div className='right-modules'>
            {user.isSignedIn && (
              <>
                <Header_User />
                <Header_Cart />
              </>
            )}
          </div>
        </div>

      </div>
      <button className="burger-menu" onClick={toggleMenu}>
        <AiOutlineMenu size={30} />
      </button>
      <div id="header-responsive-menu">
        <ul className="list-unstyled">
          {navLinks.map((item, index) => (
            <li className="py-2" key={index}>
              <Link to={item.to} className="" onClick={() => setMenuOpen(false)}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>

    </header>
  );
});

export default React.memo(Header);
