import { useState, useEffect } from 'react';
import { AiOutlineQrcode, AiOutlineLink } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { download, swalAlert } from '../../../utils';
import API from '../../../api';
import InputAutocomplete from '../../helpers/InputAutocomplete/InputAutocomplete';
import Picture from '../../helpers/Picture/Picture';

const QRCodeGenerator = () => {
  const config = useSelector((state) => state.config);
  const [references, setReferences] = useState([]);
  const [qrCodeString, setQrCodeString] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [qrCodeFetching, setQrCodeFetching] = useState(false);

  useEffect(() => {
    API.get(`/admin/references`).then(response => {
      const uniqueReferences = response.data.map(item => ({ name: item }));
      setReferences(uniqueReferences);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleSuggestionChange = (event) => {
    const { name, value } = event.target;
    if (name === 'qrCodeString') {
      setQrCode('');
      setQrCodeString(value);
    }
  };

  const handleSuggestionSelect = (name, value) => {
    if (name === 'qrCodeString') {
      setQrCode('');
      setQrCodeString(value.name);
      generateQrCode(value.name);
    }
  };

  const generateQrCode = async (str = '') => {
    if (qrCodeFetching) return;
    setQrCode('');
    if (typeof str !== 'string') {
      str = qrCodeString;
    }
    if (str.trim() !== '') {
      try {
        setQrCodeFetching(true);
        const response = await API.post('/admin/product/qrcode', { string: str });
        setQrCode(response.data.filename);
        setQrCodeString(response.data.string);
      } catch (error) {
        console.log(error);
        swalAlert(error.response.data.error.message);
      } finally {
        setTimeout(() => setQrCodeFetching(false), 500);
      }
    }
  };

  return (
    <div className="admin-home-update position-relative">
      <h4 className="">Génération de QR-code</h4>
      <div className="content d-flex justify-content-between align-items-center">
        <div className="flex-grow-1">
          <InputAutocomplete
            inputClassName='form-control-sm'
            disabled={qrCodeFetching}
            readonly={false}
            suggestionsSrc={references}
            name='qrCodeString'
            placeholder=''
            value={qrCodeString}
            displayKey="name"
            onChange={handleSuggestionChange}
            handleOnSuggestsChange={() => { }}
            formatSuggestion={(key, reference) => reference.name}
            onSuggestionSelect={handleSuggestionSelect}
            debug={false}
          />
        </div>
        <div style={{ opacity: '0.6' }} className="flex-grow-0">
          <AiOutlineQrcode size={25} title="Générer le QR Code" className="text-green pointer" onClick={generateQrCode} />
        </div>
      </div>

      {qrCode && (
        <div className='pt-3 position-relative'>
          <span
            className="close position-absolute fs-4 pointer"
            style={{ right: '4px', top: '4px' }}
            onClick={() => setQrCode('')}
          >
            &times;
          </span>
          <div style={{ width: '80%', 'maxWidth': '300px', 'margin': '0 auto' }}>
            <Picture src={qrCode} className='w-100 mb-2' />
            <pre className='text-center pointer d-block border py-1' onClick={() => download(`/utils/file?path=${qrCode}`)} title="Télécharger">
              <AiOutlineLink size={14} title="Télécharger" className="me-1" />
              {qrCodeString}
            </pre>

          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
