const AdminProductFeaturesKit = ({ product, onChange }) => {
  return (
    <div id="AdminProductFeaturesKit" className="" style={{ '--bs-border-opacity': '.2' }}>
      <div className="row" >
        <div className="col-12 col-md-8 mb-3">
          <div className="form-group">
            <label className='form-group-label' required>Nom du produit  : </label>
            <input className="form-control form-control-sm" name="prodTitre" value={product.prodTitre} onChange={onChange} />
          </div>
          <div className="form-group">
            <label className='form-group-label'>Description   : </label>
            <textarea className="form-control form-control-sm" name="prodMemo" value={product.prodMemo} onChange={onChange} >
            </textarea>
          </div>
        </div>
        <div className="col-12 col-md-4  mb-3">
          <div className="form-group rounded border py-1 px-2">
            <label className='form-group-label d-block'>Produit phare : (en page d'accueil)</label>
            <label className="radio-label me-5">
              <input type="radio" name="prodFeatured" value="0" checked={product.prodFeatured == '0'} onChange={onChange} />Non
            </label>
            <label className="radio-label">
              <input type="radio" name="prodFeatured" value="1" checked={product.prodFeatured == '1'} onChange={onChange} />Oui
            </label>
          </div>
        </div>

      </div>
    </div >
  );
}
export default AdminProductFeaturesKit;