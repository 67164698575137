// CustomerPassword.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordChecker from './../../helpers/PasswordChecker/PasswordChecker'
import './admin-customer-password.css';

function AdminCustomerPassword({ defaultValue, onChange }) {
  const { t } = useTranslation();
  const [password, setPassword] = useState(defaultValue ? defaultValue : '');

  const setPasswordIsValid = (isValid) => {
    onChange({ password: password, passwordIsValid: isValid });

  }
  return (
    <div id="CustomerPassword">
      <div className='row'>
        <div className='col-sm-6  pe-3'>
          <div className='form-group'>
            <label className='form-group-label'>Nouveau mot de passe : </label>
            <input type='text' className='form-control form-control-sm' name="userName" onChange={e => setPassword(e.target.value)} value={password} required />
          </div>
        </div>
      </div>
      <PasswordChecker password={password} isValid={v => setPasswordIsValid(v)} />
    </div >
  );
}
export default AdminCustomerPassword;
