// CustomerProfile.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debug } from './../../../utils'
import './admin-customer-profile.css';
function AdminCustomerProfile({ customer, onChange }) {
  const { t } = useTranslation();
  const handleChange = (event) => {
    onChange({ [event.target.name]: event.target.value });
  }
  return (
    <form id="AdminCustomerProfile" noValidate>
      <div className='row'>
        <div className='col-sm-6  pe-3'>
          <div className='form-group'>
            <label className='form-group-label'>Société : </label>
            <input type='text' className='form-control form-control-sm' name="userCompName" onChange={handleChange} value={customer.userCompName} required />
          </div>
          <div className='form-group'>
            <label className='form-group-label' required>Nom : </label>
            <input type='text' className='form-control form-control-sm' name="userName" onChange={handleChange} value={customer.userName} required />
          </div>
          <div className='form-group'>
            <label className='form-group-label' >Prénom : </label>
            <input type='text' className='form-control form-control-sm' name="userFirstname" onChange={handleChange} value={customer.userFirstname} />
          </div>

          <div className='form-group'>
            <label className='form-group-label' required>Email : </label>
            <input type='text' className='form-control form-control-sm' name="userEmail" onChange={handleChange} value={customer.userEmail} required />
          </div>
          <div className='form-group'>
            <label className='form-group-label'>Téléphone : </label>
            <input type='text' className='form-control form-control-sm' name="userPhone" onChange={handleChange} value={customer.userPhone} />
          </div>
        </div>
        <div className='col-sm-6 ps-3'>
          <div className='form-group'>
            <label className='form-group-label'>Adresse : </label>
            <textarea className='form-control form-control-sm' name="userAddress" onChange={handleChange} value={customer.userAddress} style={{ 'height': '117px' }} />
          </div>
          <div className='form-group'>
            <label className='form-group-label' >Code postal : </label>
            <input type='text' className='form-control form-control-sm' name="userPostcode" onChange={handleChange} value={customer.userPostcode} />
          </div>
          <div className='form-group'>
            <label className='form-group-label'>Ville : </label>
            <input type='text' className='form-control form-control-sm' name="userCity" onChange={handleChange} value={customer.userCity} />
          </div>
          <div className='form-group'>
            <label className='form-group-label'>Pays : </label>
            <input type='text' className='form-control form-control-sm' name="userCountry" onChange={handleChange} value={customer.userCountry} />
          </div>
        </div>
      </div>
      {debug(customer)}
    </form>
  );
}
export default AdminCustomerProfile;
