import React, { useEffect, useState, useRef } from 'react';
import API from './../../../api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InputAutocomplete from './../../helpers/InputAutocomplete/InputAutocomplete';
import './admin-products-list.css';
import { debug, formatPrice, swalAlert } from './../../../utils';
import { FaTrash } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
function AdminProductSpecialPrices({ product, onChange }) {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState({});
  const [specialPrices, setSpecialPrices] = useState([]);
  const [newSpecialPrice, setNewSpecialPrice] = useState({ spProdRef: product.prodRef, spUserId: '', spPrice: '', search: '' });
  const suggestCutomers = useRef(null);
  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (product.prodRef) {
      fetchSpecialPrices();
    }
  }, [product.prodRef]);

  const fetchCustomers = () => {
    API.get(`/admin/customers`)
      .then((response) => {
        const customersObj = {};
        response.data.forEach(c => {
          customersObj[c.userId] = {
            userId: c.userId,
            userCompName: c.userCompName,
            userFirstname: c.userFirstname,
            userName: c.userName,
            userPostcode: c.userPostcode,
            userCity: c.userCity,
          };
        });
        setCustomers(customersObj);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchSpecialPrices = () => {
    API.get(`/admin/product/${product.prodRef}/specialPrices`)
      .then((response) => {
        setSpecialPrices(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handlePriceUpdate = (event, index) => {
    const updatedPrices = [...specialPrices];
    updatedPrices[index].spPrice = event.target.value;
    setSpecialPrices(updatedPrices);
    if (onChange) {
      onChange(updatedPrices);
    }
  };

  const handleRemoveSpecialPrice = (index) => {
    const updatedPrices = [...specialPrices];
    updatedPrices[index].spStatus = updatedPrices[index].spStatus === "removed" ? "active" : "removed";
    setSpecialPrices(updatedPrices);
    if (onChange) {
      onChange(updatedPrices);
    }
  };

  const handleNewSpecialPriceChange = (e) => {
    console.log(e)
    const { name, value } = e.target;
    const obj = { [name]: value }
    try {
      if (name == 'spUserId') {
        if (specialPrices.filter(item => item.spUserId === value).length > 0) {
          // throw ({ message: 'Un prix special existe déja pour ce client.' })
        }
        obj.search = customers[value].userCompName
      }
      setNewSpecialPrice(prevState => ({
        ...prevState,
        ...obj
      }));
    } catch (error) {
      console.log(error)
      swalAlert(error.message)
    }
  };

  const handleAddSpecialPrice = () => {
    try {

      if (newSpecialPrice.spUserId.trim() === '' || !customers[newSpecialPrice.spUserId]) {
        throw ({ message: 'Le nom du client est requis.' })
      }
      if (newSpecialPrice.spPrice.trim() === '') {
        throw ({ message: 'Le prix spécial est requis.' })
      }
      const price = parseFloat(newSpecialPrice.spPrice);
      if (isNaN(price) || price <= 0) {
        throw new Error('Le prix spécial doit être un nombre positif.');
      }
      if (specialPrices.filter(item => item.spUserId === newSpecialPrice.spUserId).length > 0) {
        throw ({ message: 'Un prix special existe déja pour ce client.' })
      }
      const newPrice = {
        spUserId: newSpecialPrice.spUserId,
        spPrice: parseFloat(newSpecialPrice.spPrice).toFixed(2),
        spStatus: 'active'
      };
      const updatedPrices = [...specialPrices, newPrice];
      setSpecialPrices(updatedPrices);
      setNewSpecialPrice({ spProdRef: product.prodRef, spUserId: '', spPrice: '', search: '' }); // Reset form
      if (onChange) {
        onChange(updatedPrices);
      }
    } catch (error) {
      console.log(error)
      swalAlert(error.message)
      return;
    }

  };

  return (
    <div id='AdminProductSpecialPrices'>

      <table className="table table-striped table-hover table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col" className="text-center">Client</th>
            <th scope="col" className="text-center">Prix</th>
            <th scope="col" className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {specialPrices.map((sp, index) => (
            <tr key={index} className={`${sp.spStatus}`}>
              <td>
                {customers[sp.spUserId]?.userCompName}<br />
                <small>
                  {customers[sp.spUserId]?.userName} {customers[sp.spUserId]?.userFirstname}<br />
                  {customers[sp.spUserId]?.userPostcode} {customers[sp.spUserId]?.userCity}
                </small>
              </td>
              <td className="text-end" style={{ width: '120px' }}>
                <input
                  type="number"
                  name="sp"
                  value={sp.spPrice}
                  className="text-center form-control form-control-sm"
                  onChange={(e) => handlePriceUpdate(e, index)}
                />
              </td>
              <td style={{ width: '120px' }} className="text-center">
                <span className="text-green"
                  onClick={() => handleRemoveSpecialPrice(index)}>
                  <FaTrash />
                </span>
              </td>
            </tr>
          ))}

          {/* Nouvelle ligne pour ajouter un prix spécial */}
          <tr>
            <td>
              <InputAutocomplete
                inputRef={suggestCutomers}
                inputClassName='form-control-sm'
                disabled={false}
                readonly={false}
                suggestionsSrc={Object.values(customers)}
                name='spUserId'
                placeholder=''
                value={newSpecialPrice.search}
                displayKey="userCompName"
                onChange={e => setNewSpecialPrice({ ...newSpecialPrice, search: e.target.value })}
                handleOnSuggestsChange={e => { }}
                formatSuggestion={(key, customer) => customer.userCompName + ' [' + customer.userPostcode + ' ' + customer.userCity + ']'}
                onSuggestionSelect={(key, value) => handleNewSpecialPriceChange({ target: { name: 'spUserId', value: value.userId } })}
                debug={true} />

            </td>
            <td className="text-end" style={{ width: '120px' }}>
              <input
                type="number"
                name="spPrice"
                value={newSpecialPrice.spPrice}
                className="text-center form-control form-control-sm"
                onChange={handleNewSpecialPriceChange}
              />
            </td>
            <td className="text-center">
              <span onClick={handleAddSpecialPrice} className="text-green pointer">
                <AiOutlinePlus size={22} />
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      {debug(newSpecialPrice, 0)}
      {debug(specialPrices)}
    </div>
  );
}

export default AdminProductSpecialPrices;
