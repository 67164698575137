import { useState } from 'react';
import Uploader from '../../helpers/uploader/Uploader';
import { useSelector } from 'react-redux';
import API from '../../../api';
import { debug, swalAlert } from '../../../utils';
const AdminHomeUpdate = () => {

  const config = useSelector((state) => state.config);

  // Initialisationde l'état, ici un tableau pour gérer les types d'update sélectionnés
  const [what, setWhat] = useState(['stocks']);
  const [fetching, setFetching] = useState(false);
  // Gestion des clics sur les cases à cocher
  const handleCheckBoxClick = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      // Ajoute le type d'update sélectionné si la case est cochée
      setWhat((prevState) => [...prevState, name]);
    } else {
      // Retire le type d'update si la case est décochée
      setWhat((prevState) => prevState.filter(item => item !== name));
    }
  };
  const onUploadStart = () => {
    setFetching(true);
    // Logic de démarrage d'upload si nécessaire
  };
  const onUploadSuccess = (data) => {
    // console.log(data); setFetching(false);return;

    if (what.length == 0) {
      swalAlert('Sélectionnez au moins une propriété à mettre a jour (Stock et/ou Prix)')
      setFetching(false);
      return;
    }

    API.post(`/admin/products/updateFromFile`, { path: data.path, what: what })
      .then(response => {
        console.log('Update success:', response.data);
        swalAlert('Nombre de produits mis à jour : ' + response.data.updated);

      })
      .catch(error => {
        swalAlert(error.response.data.error.message);
        console.log('Error:', error);
      }).finally(() => {
        setFetching(false);
      });
  }
  const onUploadFailure = () => {
    console.log('Upload failed');
  };

  return (
    <div className="admin-home-update position-relative">
      <h4 className="">Mise à jour du catalogue</h4>
      <div className='content' style={{ 'opacity': fetching === true ? '0.5' : '1' }}>
        <div className="form-group text-center">
          <label className="checkbox-label mx-3">
            <input
              type="checkbox"
              className="form-check-input"
              checked={what.includes('stocks')}
              onChange={handleCheckBoxClick}
              name="stocks"
            /> MAJ Stocks
          </label>
          <label className="checkbox-label mx-3">
            <input
              type="checkbox"
              className="form-check-input"
              checked={what.includes('prices')}
              onChange={handleCheckBoxClick}
              name="prices"
            /> MAJ Prix
          </label>
        </div>

        {what.length > 0 ?
          <div className="d-flex justify-content-between mt-3">
            <div className="small">Télécharger le fichier csv ou xlsx</div>
            <Uploader
              inputProps={{ accept: "." + config.uploads.ldec.allowed_extensions.join(",.") }}
              upload_id='ldec'
              onUploadStart={data => onUploadStart(data)}
              onUploadSuccess={data => onUploadSuccess(data)}
              onUploadFailure={data => onUploadFailure(data)}
            />
          </div>
          : <div className="small text-center">Sélectionnez au moins une propriété à mettre a jour (Stock et/ou Prix)</div>
        }
      </div>
      {fetching === true &&
        <div
          className='position-absolute h-100 w-100 pt-4 d-flex flex-column justify-content-center align-items-center'
          style={{ top: 0, left: 0 }}>
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Traitement en cours...</span>
          </div>
        </div>}
    </div>
  );
};

export default AdminHomeUpdate;
