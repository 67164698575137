import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove as removeDiacritics } from 'diacritics';
import API from './../../../api';
import { debug } from './../../../utils'
import { useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { MdEdit, MdAttachMoney } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import AdminCustomerModal from './AdminCustomerModal';
import './admin-customers-list.css';
import './../../../css/tables.css';
import { FaCaretDown } from 'react-icons/fa';
function AdminCustomerList() {
  const { id } = useParams(); // Utiliser useParams pour accéder au paramètre :id
  const newCustomer = {
    userType: 'cust',
    userName: 'Rose',
    userCatalogs: [
      "Faisceau",
      "Kit"
    ],
    userDiscout: 0,
    userDiscout_Faisceau: 0,
    userDiscout_Attelage: 0,
    userDiscout_Kit: 0,
    userOrderEnabled: '1',
    userStatus: "active",
    userFaqRole: "contributor",
  }
  const [customers, setCustomers] = useState([]);
  const [editedCustomer, setEditedCustomer] = useState(id === 'new' ? { ...newCustomer } : null);
  const [modalContent, setModalContent] = useState(id === 'new' ? 'customer' : '');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filters, setFilters] = useState({ globalFilter: '', catalogs: ['Faisceau', 'Attelage', 'Kit'] });
  const [order, setOrder] = useState({ key: 'userCompName', direction: 'asc' });
  const { t } = useTranslation();
  useEffect(() => {
    try {
      API.get('/admin/customers').then(response => {
        setCustomers(response.data);
      });
    } catch (e) { }
  }, []);
  useEffect(() => {
    if (id) {
      if (id === 'new') {
        handleCreateCustomer()
      }
    }
  }, [id]);
  useEffect(() => {
    applyFilters();
  }, [customers, filters, order]);
  useEffect(() => {
    if (modalContent === '') {
      setEditedCustomer(null);
    }
  }, [modalContent]);
  const setOrderKey = (key) => {
    let direction = 'asc';
    if (order.key === key) {
      direction = order.direction === 'asc' ? 'desc' : 'asc';
    }
    setOrder({ key, direction });
  };
  const applyFilters = () => {
    const filteredCustomers = customers
      .filter(cust => {
        if (filters.globalFilter) {
          const searchValue = filters.globalFilter.toLowerCase();
          const compName = removeDiacritics(cust.userCompName || '').toLowerCase();
          const name = removeDiacritics(cust.userName || '').toLowerCase();
          // // const firstName = removeDiacritics(cust.userFirstname || '').toLowerCase();
          // const firstName = removeDiacritics(cust.userFirstname || '').toLowerCase();
          // if (!(compName.includes(searchValue) || name.includes(searchValue) || firstName.includes(searchValue))) {
          if (!(compName.includes(searchValue) || name.includes(searchValue))) {
            return false;
          }
        }
        if (filters.catalogs.length === 0) {
          return false;
        }
        return filters.catalogs.some(catalog => cust.userCatalogs?.includes(catalog));
      })
      .sort((a, b) => {
        const aValue = a[order.key] ? a[order.key].toString().toLowerCase() : '';
        const bValue = b[order.key] ? b[order.key].toString().toLowerCase() : '';
        return order.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    setFilteredCustomers(filteredCustomers);
  };
  useEffect(() => {
    if (editedCustomer === null) {
      setModalContent('');
    }
  }, [editedCustomer]);
  const handleCreateCustomer = () => {
    setEditedCustomer({ ...newCustomer });
    setModalContent('customer');
  }
  const handleGlobalFilterChange = (e) => {
    setFilters({ ...filters, globalFilter: removeDiacritics(e.target.value).toLowerCase() });
  };
  const handleCatalogChange = (e) => {
    const catalog = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setFilters({ ...filters, catalogs: [...filters.catalogs, catalog] });
    } else {
      setFilters({ ...filters, catalogs: filters.catalogs.filter(c => c !== catalog) });
    }
  };
  const handleEditCustomer = (customer) => {
    setEditedCustomer(customer);
    setModalContent('customer');
  };
  const handleEditSpecialPrices = (customer) => {
    setModalContent('specialPrices');
    setEditedCustomer(customer);
  };
  const handleCustomerCreated = (customer) => {
    setCustomers([...customers, customer]);
  };
  const handleCustomerUpdated = (customer) => {
    setCustomers(customers.map(cust => cust.userId === customer.userId ? customer : cust));
    if (customer.userStatus.startsWith('supp')) {
      // setModalContent('');
      setEditedCustomer(null);
    }
  };
  const handleOnModalHide = () => {
    // setModalContent('');
    setEditedCustomer(null);
  };
  return (
    <div id="CustomersList">
      <div className="container">
        <div className="page-title">
          <div className="col-12 col-md-3"></div>
          <div className="col-12 col-md-6">
            <h1 className="page-title">{t('Customers list')}</h1>
          </div>
          <div className="col-12 col-md-3 text-end ">
            <button className="btn btn-green" onClick={handleCreateCustomer}>
              <FaPlus size={14} style={{ top: "-1px" }} className="position-relative me-2" />Ajouter
            </button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="input-group">
              <span className="input-group-text bg-green text-white">
                <FaSearch />
              </span>
              <input
                type="text"
                placeholder="Rechercher par nom, prénom ou société"
                value={filters.globalFilter || ''}
                onChange={handleGlobalFilterChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6 d-flex flex-row align-content-center justify-content-end flex-wrap">
            <label className="form-check d-inline-block me-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Faisceau"
                onChange={handleCatalogChange}
                checked={filters.catalogs.includes('Faisceau')}
              />
              Faisceaux
            </label>
            <label className="form-check d-inline-block ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                value="Attelage"
                onChange={handleCatalogChange}
                checked={filters.catalogs.includes('Attelage')}
              />
              Attelages
            </label>
            <label className="form-check d-inline-block ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                value="Kit"
                onChange={handleCatalogChange}
                checked={filters.catalogs.includes('Kit')}
              />
              Kits
            </label>
          </div>
        </div>
        <div className="table-container">
          <table className={`table table-striped table-sm order-${order.direction}`}>
            <thead>
              <tr>
                <th onClick={() => setOrderKey('userCompName')} className="pointer" >
                  Société
                  {order.key === 'userCompName' && <FaCaretDown className="carret" />}
                </th>
                <th onClick={() => setOrderKey('userName')} className="pointer">Nom
                  {order.key === 'userName' && <FaCaretDown className="carret" />}
                </th>
                <th>Téléphone</th>
                <th>Email</th>
                <th>Ville</th>
                <th>Catalogues</th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map(customer => (
                <tr key={customer.userId}>
                  <td className="text-center">{customer.userCompName}</td>
                  <td className="text-center">{customer.userName}{customer.userFirstname}</td>
                  <td className="text-center">{customer.userPhone}</td>
                  <td className="text-center">{customer.userEmail}</td>
                  <td className="text-center">{customer.userCity}</td>
                  <td className="text-center">{customer.userCatalogs?.join(', ')}</td>
                  <td><span className="text-green pointer" onClick={() => handleEditCustomer(customer)} title="Paramètres"><MdEdit size={18} /></span></td>
                  <td><span className="text-green fs-6 fw-bold mx-2  pointer" onClick={() => handleEditSpecialPrices(customer)} title="Prix spéciaux">€</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {debug(filters)}
      {debug(filteredCustomers)}
      {(modalContent !== '' && editedCustomer) && (
        <AdminCustomerModal
          onHide={handleOnModalHide}
          modalContent={modalContent}
          editedCustomer={editedCustomer}
          handleCustomerCreated={handleCustomerCreated}
          handleCustomerUpdated={handleCustomerUpdated}
        />
      )}
    </div>
  );
}
export default AdminCustomerList;
