const AdminProductFeaturesFaisceau = ({ product, onChange }) => {
  return (
    <div id="AdminProductFeaturesFaisceau" className="" style={{ '--bs-border-opacity': '.2' }}>
      <div className='row'>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Type : </label>
            <select className="form-control form-control-sm" name="prodType" value={product.prodType} onChange={onChange}>
              <option value=""></option>
              <option value="7">7</option>
              <option value="13">13</option>
            </select>
          </div>

        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Autodiagnostic  : </label>
            <select className="form-control form-control-sm" name="prodLEDSupport" value={product.prodLEDSupport} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>LED-support : </label>
            <select className="form-control form-control-sm" name="prodType" value={product.prodLampeRemplacement} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Lampe de remplacement : </label>
            <select className="form-control form-control-sm" name="prodType" value={product.prodType} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Aide de programmation : </label>
            <select className="form-control form-control-sm" name="prodAideProgrammation" value={product.prodAideProgrammation} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>&nbsp; <br /> Couplage  : </label>
            <select className="form-control form-control-sm" name="prodClouplage" value={product.prodClouplage} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Check : </label>
            <select className="form-control form-control-sm" name="prodCheck" value={product.prodCheck} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>PDC : </label>
            <select className="form-control form-control-sm" name="prdPdc" value={product.prdPdc} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
              <option value="E3">E3</option>
              <option value="E5">E5</option>
              <option value="E6">E6</option>
              <option value="EK">EK</option>
              <option value="F1">F1</option>
              <option value="O1">O1</option>
              <option value="O3">O3</option>
              <option value="O4">O4</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Plus : </label>
            <select className="form-control form-control-sm" name="prdPlus" value={product.prdPlus} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
              <option value="D1">D1</option>
              <option value="DL">DL</option>
              <option value="DL2">DL2</option>
              <option value="L1">L1</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Ligne : </label>
            <select className="form-control form-control-sm" name="prodLigne" value={product.prodLigne} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
              <option value="D1">D1</option>
              <option value="DL">DL</option>
              <option value="DL2">DL2</option>
              <option value="L1">L1</option>
            </select>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Alimentation  : </label>
            <select className="form-control form-control-sm" name="prodAlimentation" value={product.prodAlimentation} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
            </select>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>CAN  : </label>
            <select className="form-control form-control-sm" name="prodCan" value={product.prodCan} onChange={onChange}>
              <option value=""></option>
              <option value="–">Non</option>
              <option value="●">Oui</option>
              <option value="Ar">Oui</option>
              <option value="Av">Oui</option>
              <option value="Av/Ar">Oui</option>
            </select>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className="form-group">
            <label className='form-group-label'>Montage  : </label>
            <select className="form-control form-control-sm" name="prodMontage" value={product.prodMontage} onChange={onChange}>
              <option value=""></option>
              <option value="A">A</option>
              <option value="A/B">A/B</option>
              <option value="A/C">A/C</option>
              <option value="A/D">A/D</option>
              <option value="B">B</option>
              <option value="B/C">B/C</option>
              <option value="C">C</option>
              <option value="C/D">C/D</option>
              <option value="D">D</option>
            </select>
          </div>
        </div>
      </div>


    </div>
  );


}
export default AdminProductFeaturesFaisceau;