import React from 'react';

const AdminProductFeaturesAttelage = ({ product, onChange }) => {
  return (
    <div>
      <div className="row">
        {/* Poids */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Poids&nbsp;:</label>
            <input
              type="text"
              name="prodPoids"
              value={product.prodPoids}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>
        {/* MR */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>MR&nbsp;:</label>
            <input
              type="text"
              name="prodMR"
              value={product.prodMR}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>
        {/* S */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Poids sur flèche&nbsp;:</label>
            <input
              type="text"
              name="prodPoidsSurFleche"
              value={product.prodPoidsSurFleche}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>
      </div>
      <div className="row">
        {/* Dépose PC */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Dépose PC&nbsp;:</label>
            <input
              type="text"
              name="prodDeposePC"
              value={product.prodDeposePC}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>

        {/* Découpe PC */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Découpe PC&nbsp;:</label>
            <input
              type="text"
              name="prodDecoupePC"
              value={product.prodDecoupePC}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>

        {/* Perçage */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Perçage&nbsp;:</label>
            <input
              type="text"
              name="prodPercage"
              value={product.prodPercage}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>
      </div>

      <div className="row">
        {/* Adapt. SPV */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Adapt. SPV&nbsp;:</label>
            <input
              type="text"
              name="prodAdaptSPV"
              value={product.prodAdaptSPV}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>

        {/* Temps montage */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Temps montage&nbsp;:</label>
            <input
              type="text"
              name="prodMontage"
              value={product.prodMontage}
              onChange={onChange}
              className="form-control form-control-sm short"
            />
          </div>
        </div>

        {/* Statut */}
        <div className="col-sm-4">
          <div className="form-group">
            <label className='form-group-label'>Statut&nbsp;:</label>
            <select
              name="prodStatut"
              value={product.prodStatut}
              onChange={onChange}
              className="form-control form-control-sm"
            >
              <option value="disponible">Disponible</option>
              <option value="non disponible">Non disponible</option>
              <option value="supprimee">A supprimer</option>
            </select>
          </div>
        </div>
      </div>
      {/* Commentaire */}
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label className='form-group-label'>Commentaire&nbsp;:</label>
            <textarea
              name="prodMemo"
              value={product.prodMemo}
              onChange={onChange}
              className="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductFeaturesAttelage;
