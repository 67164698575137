// CustomerAdmin.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


import { formatDate } from './../../../utils';
import './admin-customer-admin.css';

function AdminCustomerAdmin({ customer, onChange }) {
  const { t } = useTranslation();
  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if (event.target.name == 'userCatalogs') {
      value = [...customer.userCatalogs];
      const index = value.indexOf(event.target.value);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(event.target.value);
      }
    }
    onChange({ [key]: value });
  }
  return (
    <form id="CustomerAdmin" noValidate>
      <div className='row'>
        <div className='col-sm-6 pe-3'>
          <div className='form-group' style={{ height: '60px' }}>
            <label className='form-group-label' required>Catalogues : </label>
            <div>
              <label className='checkbox-label me-3'>
                Faisceaux
                <input type='checkbox' name="userCatalogs" onChange={e => []} onClick={handleChange} value="Faisceau" checked={customer.userCatalogs?.indexOf('Faisceau') > -1} />
              </label>
              <label className='checkbox-label me-3'>
                Attelages
                <input type='checkbox' name="userCatalogs" onChange={e => []} onClick={handleChange} value="Attelage" checked={customer.userCatalogs?.indexOf('Attelage') > -1} />
              </label>
              <label className='checkbox-label'>
                Kits
                <input type='checkbox' name="userCatalogs" onChange={e => []} onClick={handleChange} value="Kit" checked={customer.userCatalogs?.indexOf('Kit') > -1} />
              </label>{/*  */}

            </div>
          </div>
          <div className='form-group'>
            <div className='row'>
              <div className='col-4'>
                <label className='form-group-label text-center'  >Rem. faisceaux :</label>
                <div className='input-group  input-group-sm'>
                  <input type='number' className='form-control form-control-sm' name="userDiscout_Faisceau" onChange={handleChange} value={customer.userDiscout_Faisceau} />
                  <span className='input-group-text'>%</span>
                </div>
              </div>
              <div className='col-4'>
                <label className='form-group-label text-center' >Rem. attelages :</label>
                <div className='input-group input-group-sm'>
                  <input type='number' className='form-control form-control-sm' name="userDiscout_Attelage" onChange={handleChange} value={customer.userDiscout_Attelage} />
                  <span className='input-group-text'>%</span>
                </div>
              </div>
              <div className='col-4'>
                <label className='form-group-label text-center' >Rem. kits :</label>
                <div className='input-group  input-group-sm'>
                  <input type='number' className='form-control form-control-sm' name="userDiscout_Kit" onChange={handleChange} value={customer.userDiscout_Kit} />
                  <span className='input-group-text'>%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6  ps-3'>
          <div className='form-group'>
            <label className='form-group-label' required >Statut : </label>
            <select className='form-control form-control-sm' name="userStatus" onChange={handleChange} value={customer.userStatus} >
              <option value="actif" selected={customer.userStatus === 'actif'}>Activé</option>
              <option value="desactivé" selected={customer.userStatus === 'desactivé'}>Desactivé</option>
              <option value="supprimé" selected={customer.userStatus === 'supprimé'}>Compte à supprimer</option>
            </select>
          </div>
          <div className='form-group'>
            <label className='form-group-label' required>Commandes autorisées : </label>
            <select className='form-control form-control-sm' name="userOrderEnabled" onChange={handleChange} value={customer.userOrderEnabled} >
              <option value="1" selected={customer.userOrderEnabled === '1'}>Oui</option>
              <option value="0" selected={customer.userOrderEnabled === '0'}>Non</option>
            </select>
          </div>
          <div className='form-group'>
            <label className='form-group-label' required >FAQ / Forum : </label>
            <select className='form-control form-control-sm' name="userFaqRole" onChange={handleChange} value={customer.userFaqRole} >
              <option value="none" selected={customer.userFaqRole === 'none'}>Pas d'accès</option>
              <option value="reader" selected={customer.userFaqRole === 'reader'}>Lecture</option>
              <option value="contributor" selected={customer.contributor === 'contributor'}>Contributeur</option>
              <option value="admin" selected={customer.userFaqRole === 'admin'}>Administrateur</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <p className='small text-gray-9'>
        <span className='d-inline-block me-5'>Date de création : {customer.userCreatedAt}</span>
        <span className='d-inline-block me-5'> Dernière connexion :  {customer.userLastConnexionAt}</span></p>

    </form>
  );
}
export default AdminCustomerAdmin;
