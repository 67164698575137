// userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import API from './../api';
const userSlice = createSlice({

  name: 'user',
  initialState: {
    isSignedIn: (localStorage.getItem('signedIn') === '1' || localStorage.getItem('signedIn') === 'true') ? true : false, // Ajoute un champ isSignedIn
  },
  reducers: {
    // isSignedIn: () => {
    //   return
    // },
    setUser: (state, action) => {
      for (const k in action.payload) {
        state[k] = action.payload[k]
      }
      state.isSignedIn = true; // Définit isSignedIn à true lorsque l'utilisateur est connecté
      localStorage.setItem('signedIn', true);
    },
    updateUser: (state, action) => {
      for (const k in action.payload) {
        state[k] = action.payload[k]
      }
    },
    clearUser: (state) => {
      state.isSignedIn = false;
      for (let key in state) {
        if (key !== 'isSignedIn') {
          state[key] = Array.isArray(state[key]) ? [] : null;
          state[key] = undefined;
        }
      }
      localStorage.removeItem('token');
      localStorage.removeItem('signedIn');
      API.post('/user/signOut');
      // window.postMessage({ type: 'userSignIn', signedIn: false }, '*');

    },
  },
});
export const { setUser, updateUser, clearUser } = userSlice.actions;
export const getUserToken = (state) => state.token;
export default userSlice.reducer;
export const fetchUser = () => async (dispatch, getState) => {

  const state = getState().user
  if (state.isSignedIn) {
    try {
      const response = await API.get('/user');
      if (response.data.status != 'active') {
        dispatch(clearUser());
      } else {
        dispatch(updateUser(response.data));
      }
    } catch (e) { }
  }
};



