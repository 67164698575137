// Header_User.js
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BiCart } from "react-icons/bi";
import './header-cart.css';
import { loadCart } from '../../redux/cartSlice';
function Header_Orders() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [itemCount, setItemCount] = useState(0);
  useEffect(() => {
    dispatch(loadCart())
  }, []);
  useEffect(() => {
    console.log(cart);
    let count = 0;
    cart.items.forEach(it => {
      count += it.itQuantity;
    });
    setItemCount(count);
  }, [cart]);
  return (
    <div className="header-cart">
      <Link className='cart badge-wrapper' to="/cart">
        <BiCart size="30" className="text-green" />
        {itemCount > 0 && <span className='badge bg-danger'>{itemCount}</span>}
      </Link>
    </div>
  );
}
export default Header_Orders;
