import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import UserSignIn from './UserSignIn';
import UserLostPwd from './UserLostPwd';
import UserSignUp from './UserSignUp';
import UserPwd from './UserPwd';
import API from '../../api';
import './user-signin-modal.css';
import { debug } from './../../utils';
function UserSignInModal({ show, onSuccess = null, onClose = null, options = null }) {
  const iframeRef = useRef(null);
  const defaultTab = 'signIn';
  const user = useSelector((state) => state.user);
  const [tab, setTab] = useState(defaultTab);
  const [optionsCopy, setOptionsCopy] = useState(options);
  useEffect(() => {
    setOptionsCopy(options);
  }, [options])
  useEffect(() => {
    if (user.isSignedIn) {
      onSuccess(user);
    }
  }, [user])
  const abort = () => {
    if (typeof options?.onAbort == 'function') {
      options.onAbort()
    } else {
      window.location.href = '/';
    }
  }
  const handleOnTabSwitch = (newTab) => {
    if (newTab != 'close') {
      setTab(newTab != '' ? newTab : defaultTab);
    } else {
      setTab(defaultTab);
      handleOnClose();
    }
  }
  const handleOnClose = (newTab) => {
    if (tab !== defaultTab) {
      setTab(defaultTab);
    } else {
      if (onClose) {
        onClose();
      }
    }
  }
  return (
    <Modal show={show} onHide={abort} className='userSignInModal'>
      <Modal.Header closeButton className="text-center">
        <img src="/img/logo.png" alt="amd jaeger" className="logo" />
      </Modal.Header>
      <Modal.Body>
        {tab === 'signIn' && <UserSignIn onTabSwitch={handleOnTabSwitch} options={optionsCopy} />}
        {(optionsCopy?.userType && tab === 'signUp') && <UserSignUp onTabSwitch={handleOnTabSwitch} options={optionsCopy} />}
        {tab === 'lostPwd' && <UserLostPwd onTabSwitch={handleOnTabSwitch} options={optionsCopy} updateOptions={payload => setOptionsCopy({ ...optionsCopy, ...payload })} />}
        {tab === 'newPwd' && <UserPwd onTabSwitch={handleOnTabSwitch} options={optionsCopy} updateOptions={payload => setOptionsCopy({ ...optionsCopy, ...payload })} onSuccess={onSuccess} />}
      </Modal.Body>
    </Modal>
  );
}
export default UserSignInModal;
