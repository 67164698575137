import React, { useEffect, useState } from 'react';
import API from './../../api';
import { swalAlert } from './../../utils';
import './customer-account.css'; // Si tu souhaites ajouter du style

const CustomerProfil = () => {
  const [customer, setCustomer] = useState({});
  const [isValidEmail, setIsValidEmail] = useState(true);

  useEffect(() => {
    fetchUser();
  }, []);
  const fetchUser = async () => {
    try {
      const response = await API.get('/customer/profile');
      setCustomer(response.data);
      checkEmail(response.data.userEmail)

    } catch (error) {
      console.log(error)
      swalAlert("Vos données n'ont pu être téléchargées");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };
  const handleSubmit = async (e) => {
    try {
      API.handleError = false;
      const response = await API.put('/customer/profile', customer);
      swalAlert("Vos données ont été mises à jour", "success");
      setCustomer(response.data);
    } catch (error) {
      swalAlert(error.response.data.error.message, "error");
    }
  };
  const checkEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  };
  if (!customer) {
    return <div>Chargement des données...</div>;
  }
  return (
    <div className="customer-account">
      <form className="px-3"  >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-group-label">Prénom</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userFirstname"
                value={customer.userFirstname}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Nom</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userName"
                value={customer.userName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label className="form-group-label">
                Email

              </label>
              <input
                type="email"
                className="form-control form-control-sm"
                name="userEmail"
                value={customer.userEmail}
                onChange={handleChange}
                required
                onBlur={e => checkEmail(e.target.value)}
              />
              {(customer && !isValidEmail) && <span span className="fs-8 text-danger fw-normal">Une adresse email valide est requise</span>}
            </div>
            <div className="form-group">
              <label className="form-group-label">Téléphone</label>
              <input
                type="tel"
                className="form-control form-control-sm"
                name="userPhone"
                value={customer.userPhone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-group-label">Entreprise</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userCompName"
                value={customer.userCompName}
                onChange={handleChange}

              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Adresse</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userAddress"
                value={customer.userAddress}
                onChange={handleChange}

              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Code postal</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userPostcode"
                value={customer.userPostcode}
                onChange={handleChange}

              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Ville</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userCity"
                value={customer.userCity}
                onChange={handleChange}

              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Pays</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="userCountry"
                value={customer.userCountry}
                onChange={handleChange}

              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-green"
          disabled={!isValidEmail}
          onClick={handleSubmit}>Sauvegarder</button>
      </form>
    </div >

  );
};
export default CustomerProfil;
