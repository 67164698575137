import React, { useEffect, useState } from 'react';
import API from './../../../api';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { debug } from './../../../utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const AdminOrdersChart = ({ title = true }) => {
  const [data, setData] = useState(null);
  const [salesData, setSalesData] = useState([]);
  const [dataSrc, setDataSrc] = useState('parts');
  // Colors for different data sources
  const colors = {
    parts: { bars: 'rgba(52, 152, 219, 0.4)', texts: 'rgba(52, 152, 219,1)' },
    orders: { bars: 'rgba(255, 180, 100, 0.5)', texts: 'rgba(255, 180, 100,1)' },

    turnover: { bars: 'rgba(155, 176, 39, 0.4)', texts: 'rgba(155, 176, 39,1)' }
  };
  const [monthLabels, setMonthLabels] = useState([]);
  // Fetch data from API
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await API.get('/admin/orders/stats');
        const salesData = response.data;

        const month_ints = salesData[0].map(item => item.month);
        const month_texts = month_ints.map(item => {
          const date = new Date(`2024/${item}/01`);
          return new Intl.DateTimeFormat('fr', { month: 'short' }).format(date).substring(0, 3);
        });
        setMonthLabels(month_texts);
        setSalesData(salesData);
        updateChartData(salesData, dataSrc, month_texts);
      } catch (error) {
        console.error("Failed to fetch stats:", error);
      }
    };
    fetchStats();
  }, []);
  // Update chart when dataSrc or salesData change
  useEffect(() => {
    if (salesData.length > 0) {
      updateChartData(salesData, dataSrc, monthLabels);
    }
  }, [dataSrc, salesData, monthLabels]);

  // Update chart data
  const updateChartData = (salesData, dataSrc, labels) => {
    const datasets = [
      {
        label: '',
        data: salesData[0].map(item => item[dataSrc]),
        backgroundColor: 'rgba(153, 153, 153, 0.5)',  // Same color for N-1
      },
      {
        label: '',
        data: salesData[1].map(item => item[dataSrc]),
        backgroundColor: colors[dataSrc]['bars'],  // Different shades for N
      }
    ];
    // Add average basket trend line if dataSrc is 'orders'

    if (dataSrc === 'orders') {
      const partsPerOrderN1 = salesData[0].map(item => item.parts / item.orders); // N-1
      const partsPerOrderN = salesData[1].map(item => item.parts / item.orders);   // N      
      datasets.push({
        label: '',
        data: partsPerOrderN1,
        type: 'line',  // Line chart
        fill: false,
        borderColor: 'rgba(153, 153, 153, 0.7)',
        backgroundColor: 'rgba(153, 153, 153, 0)',
        tension: 0.4, // Make the line smooth
        yAxisID: 'y-axis-parts-per-order',  // Use another secondary Y-axis for parts per order
      },
        // Ligne pour le nombre de pièces par commande N
        {
          label: '',
          data: partsPerOrderN,
          type: 'line',  // Line chart
          fill: false,
          borderColor: colors['orders']['texts'],
          backgroundColor: 'rgba(255, 99, 132, 0.4)',
          tension: 0.4, // Make the line smooth
          yAxisID: 'y-axis-parts-per-order',  // Use another secondary Y-axis for parts per order
        });
    }
    if (dataSrc === 'turnover') {
      const averageBasketN1 = salesData[0].map(item => item.turnover / item.orders); // N-1
      const averageBasketN = salesData[1].map(item => item.turnover / item.orders);   // N

      datasets.push(
        {
          label: '',
          data: averageBasketN1,
          type: 'line',  // Line chart
          fill: false,
          borderColor: 'rgba(153, 153, 153, 0.3)',
          backgroundColor: 'rgba(153, 153, 153, 0)',
          tension: 0.4, // Make the line smooth
          yAxisID: 'y-axis-basket',  // Use a secondary Y-axis for basket average

        },
        {
          label: '',
          data: averageBasketN,
          type: 'line',  // Line chart
          fill: false,
          borderColor: colors['turnover']['texts'],
          backgroundColor: 'rgba(255, 159, 64, 0)',
          tension: 0.4, // Make the line smooth
          yAxisID: 'y-axis-basket',  // Use the secondary Y-axis for basket average

        }
      );
    }
    setData({ labels, datasets });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          filter: item => item.text !== '' // Filtrer les labels vides
        }
      },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: '',
        },
      },
      ...(dataSrc === 'turnover' && {  // Ajouter conditionnellement l'axe y-axis-basket
        'y-axis-basket': {
          beginAtZero: true,
          position: 'right',
          grid: {
            drawOnChartArea: false,  // Seulement sur l'axe primaire
          },
          min: 0,
          max: 300,  // Ajuster selon tes données
          title: {
            display: true,
            text: 'Panier moyen',
          },
        },
      }),
      ...(dataSrc === 'orders' && {  // Ajouter conditionnellement l'axe y-axis-parts-per-order
        'y-axis-parts-per-order': {
          beginAtZero: true,
          position: 'right',
          grid: {
            drawOnChartArea: false,  // Seulement sur l'axe primaire
          },
          min: 0,
          max: 5,  // Ajuster selon tes données
          title: {
            display: true,
            text: 'Pièces par commande',
          },
        },
      }),
    },
  };



  return (
    <div>
      {title != false &&
        <h2>Comparaison des ventes mensuelles N vs N-1</h2>
      }
      {data && <Bar data={data} options={options} />}
      <div className='text-center fs-8 text-uppercase my-3'>
        <span
          onClick={() => setDataSrc('parts')}
          className={`px-2 pointer`}
          style={{ color: dataSrc === 'parts' ? colors['parts']['texts'] : '#999' }}
        >
          Pièces
        </span>|

        <span
          onClick={() => setDataSrc('orders')}
          className={`px-2 pointer`}
          style={{ color: dataSrc === 'orders' ? colors['orders']['texts'] : '#999' }}
        >
          Commandes
        </span>|
        <span
          onClick={() => setDataSrc('turnover')}
          className='px-2 pointer' style={{ color: dataSrc === 'turnover' ? colors['turnover']['texts'] : '#999' }}
        >
          Ventes
        </span>
      </div>
    </div>
  );
};

export default AdminOrdersChart;
