import React from 'react';
import Modal from 'react-bootstrap/Modal';
import AdminCustomerEdit from './AdminCustomerEdit';
import AdminCustomerSpecialPrices from './AdminCustomerSpecialPrices';

const AdminCustomerModal = ({ onHide, modalContent, editedCustomer, handleCustomerCreated, handleCustomerUpdated }) => {
  return (
    <Modal show={true} onHide={onHide} className="customer-modal modal-lg" keyboard={true}>
      <Modal.Header closeButton>
        <h4>
          {editedCustomer.userId === '' ? <>Nouveau client</> :
            <>
              {editedCustomer.userName} {editedCustomer.userFirstname}
              {editedCustomer.userCompName?.trim() !== '' && <> / {editedCustomer.userCompName}</>}
            </>
          }
        </h4>
      </Modal.Header>
      <Modal.Body>
        {modalContent === 'customer' &&
          <AdminCustomerEdit
            customer={editedCustomer}
            onCreated={handleCustomerCreated}
            onUpdated={handleCustomerUpdated}
          />
        }
        {modalContent === 'specialPrices' &&
          <AdminCustomerSpecialPrices
            userId={editedCustomer.userId}
            onSaved={() => onHide()}
          />
        }
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminCustomerModal;
