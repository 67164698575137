import { useEffect } from 'react';
import AuthGuard from './AuthGuard';
import { useSelector } from 'react-redux';
import './mainLayout.css';
const MainLayout = ({ children }) => {
  // const user = useSelector((state) => state.user);
  useEffect(() => {
    document.body.classList.add('main-layout');
    return () => {
      document.body.classList.remove('main-layout');
    };
  }, []);
  return (

    <AuthGuard>
      <div className="main-layout">
        {children}
      </div>
    </AuthGuard>

  );
};

export default MainLayout;