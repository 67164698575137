// Cart.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatDate, download } from './../../utils';

import API from './../../api';
import { Link } from "react-router-dom";
import OrderItem from './../orders/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearch } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { CiViewList } from "react-icons/ci";
import { debug } from './../../utils';
import './orders.css';
import './../../css/tables.css';
import { loadCart, updateCartItems } from '../../redux/cartSlice';
import ProductModal from '../products/ProductModal';
function Orders() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart);
  const [completed, setCompleted] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [ordersByYears, setOrdersYears] = useState({});
  const [filter, setFilter] = useState('');
  const [loadedLength, setLoadedLength] = useState(0);
  const load = () => {
    const limit = 100;
    API.get('/orders?l=' + allOrders.length + ',' + limit).then(response => {
      setAllOrders([...allOrders, ...response.data]);
      // setLoadedLength(loadedLength + response.data.length);

      setCompleted(response.data.length < limit);
    });
  }
  const distributeOrdersByYears = () => {
    const escapedFilter = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFilter, 'i');
    const orders = {}
    allOrders.forEach(order => {
      if (regex.test(order.orderRef) || regex.test(order.orderInternalRef)) {
        const year = order.orderDate.substr(0, 4);
        if (!orders[year]) {
          orders[year] = [];
        }
        orders[year].push(order);
      }
    });
    setOrdersYears(orders);
  }
  useEffect(() => {
    distributeOrdersByYears()
  }, [allOrders, filter]);
  useEffect(() => {
    load();
  }, []);
  /*
  const handleItemUpdate = (updatedItem) => {
    const updatedItems = cart.items.map(item =>
      item.itId === updatedItem.itId ? updatedItem : item
    );
    dispatch(updateCartItems(updatedItems))
  }
  const handleItemClick = (item) => {
    setSelectedItem(item)
  }
  const handleCloseModal = () => {
    setSelectedItem(null);
  }
  */
  return (
    <div id="orders">
      <div className="page-title">
        <h1>{t('Orders')}</h1>
      </div>
      <div className='container'>
        <div className='row mb-3'>
          <div className='col-sm-6'>
            <div className='form-group'>
              <div className='input-group'>
                <div className='input-group-text'><BiSearch /></div>
                <input type='text' className='form-control' value={filter} name='filter' onChange={e => setFilter(e.target.value)} placeholder={t('Search by ref')} />
              </div>
            </div>
          </div>
          <div className='col-sm-6 text-end pt-2'>
            {cart.items.length > 0 &&
              <span><FiAlertTriangle className="me-2  text-green position-relative" style={{ top: '-1px' }} />
                <Link to='/cart' className='text-green default'>
                  {t('You have an order in progress')}.
                </Link>
              </span>
            }
          </div>
        </div>
        <table className='table table-sm'>
          <tbody>
            {Object.keys(ordersByYears).reverse().map((year, y) => (
              <>
                <tr className='year' key={y}>
                  <th key={year} className='text-green pb-2' >
                    {year}
                  </th>
                  <td colSpan={6}></td>
                </tr>
                {ordersByYears[year].map(
                  (order, idx) => (
                    <tr key={idx} className={idx % 2 === 0 ? 'bg' : ''}>
                      <td className='text-center ps-3 pe-3'>{order.orderRef}</td>
                      <td>{order.orderInternalRef}</td>
                      <td>{formatDate(order.orderDate)}</td>
                      <td>{formatPrice(order.orderTotalPriceET)}</td>
                      <td>{formatPrice(order.orderVATAmount)}</td>
                      <td>{formatPrice(order.orderTotalPriceIT)}</td>
                      <td className='text-end pe-3 text-green'>{order.orderPDFFilename && (
                        <a onClick={e => download(`/order/pdf/${order.orderId}`, t)}>
                          <CiViewList size="24" />
                        </a>
                      )}</td>
                    </tr>
                  )
                )}
                <tr className='spacer'>
                  <td colSpan={8} className='pt-5'></td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        {completed == false && allOrders.length > 0 &&
          <div className='text-center'>
            <button className='btn btn-green btn-sm' onClick={load}>{t('Load more')}</button>
          </div>
        }

      </div >
    </div >
  );
}
export default Orders;
