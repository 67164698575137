import React, { useEffect, useState } from 'react';
import API from './../../api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ProductModal from './ProductModal';
import './products-list.css';
import Catalog from './Catalog';

function ProductsList() {
  const { t } = useTranslation();
  const location = useLocation();
  const [defaultCatalog, setDefaultCatalog] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductModal, setShowProductModal] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (selectedProduct) {
      setShowProductModal(true);
    }
  }, [selectedProduct]);
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };
  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowProductModal(false);
  };
  useEffect(() => {
    // Extract the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const paramCValue = searchParams.get('cat');
    setDefaultCatalog(paramCValue);
  }, [location.search]);

  return (
    <div id='ProductsList'>
      <div className="page-title">
        <h1>Commander ou rechercher une fiche technique </h1>
      </div>
      <div className='container'>
        {/* Pass the "c" parameter to the Catalog component or use it here as needed */}
        <Catalog isAdmin={false} lastUpdate={0} defaultCatalog={defaultCatalog} onSelect={handleProductSelect} />
      </div>
      {showProductModal && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
    </div>
  );
}

export default ProductsList;
