import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
const InputPassword = ({ onChange, value = '', className = '', size = '', inputClassName = '', ...restProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={`input-group ${size !== '' ? ' input-group-' + size : ''} ${className}`}>
      <input
        type={showPassword ? 'text' : 'password'}
        className={`form-control ${inputClassName}`}
        placeholder="Mot de passe"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        {...restProps}
      />
      <button
        className="btn btn-outline-secondary pt-0"
        type="button"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <BsEyeSlash /> : <BsEye />}
      </button>
    </div>
  );
};

export default InputPassword;
