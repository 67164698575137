// AppLayout.js
import React, { useEffect } from 'react';
import Header from '../components/common/Header';
import AdminHeader from '../components/common//AdminHeader';
import DefaultLayout from './DefaultLayout';
import MainLayout from './MainLayout';
import AdminLayout from './AdminLayout';
import { useSelector } from 'react-redux';
import './app-layout.css';
const AppLayout = ({ children, requireAuth, requireAdmin }) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="app-layout">
      {requireAdmin ? <AdminHeader /> : <Header />
      }
      <div className='' id="content">
        {requireAdmin ?
          <AdminLayout>{children}</AdminLayout>
          :
          requireAuth ?
            <MainLayout>{children}</MainLayout>
            :
            <DefaultLayout>{children}</DefaultLayout>
        }
      </div>
    </div >
  );
}
export default AppLayout;
