import React, { useEffect, useState } from 'react';
import API from './../../../api';
const Picture = ({
  src,
  size = '',
  width = '',
  height = '',
  className = '',
  style = {}
}) => {
  const [file, setFile] = useState({
    contents: ''
  });
  useEffect(() => {
    if (src) {
      get_contents();
    }

  }, [src])
  const get_contents = async () => {
    try {
      // const extension = src.split('.').pop().toLowerCase();
      // const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      let url = '';
      // if (!imageExtensions.includes(extension)) {
      //   url = src;
      // } else {
      url = `/utils/file?path=${src}${size !== '' ? '&size=' + size : ''}`
      // }
      API.handleError = false;
      const response = await API.get(url);
      setFile(response.data);
    } catch (error) {

      file.contents = 'PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iNjQwcHgiIGhlaWdodD0iNDgwcHgiIHZpZXdCb3g9IjAgMCA2NDAgNDgwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2NDAgNDgwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHJlY3QgZmlsbD0iI0RERERERCIgd2lkdGg9IjYzOS45OTkiIGhlaWdodD0iNDc5Ljk5OSIvPgo8dGV4dCB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAxIDkyLjY2ODkgMjU3LjQ3ODUpIiBmb250LWZhbWlseT0iJ015cmlhZFByby1SZWd1bGFyJyIgZm9udC1zaXplPSI1OS41NTA3Ij5JbWFnZSBpbnRyb3V2YWJsZTwvdGV4dD4KPC9zdmc+Cg==';
      file.mimetype = 'image/svg+xml';
      console.log(error)
    }
  }
  return (
    <>
      {file?.contents != '' &&
        <img
          src={`data:${file.mimetype};base64,${file.contents}`}
          alt='' className={`${className}`}
          style={{
            width: width ? `${width}px` : undefined,
            height: height ? `${height}px` : undefined,
            ...style
          }}
        />
      }
    </>
  );
};
export default Picture;

