// Header_User.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearUser, fetchUser } from './../../redux/userSlice';
import { FiUser, FiLogIn, FiLogOut } from 'react-icons/fi';
import { BiLogIn } from 'react-icons/bi';
import { HiOutlineLogin, HiOutlineLogout } from 'react-icons/hi';
import { debug } from './../../utils'
import UserSignInModal from './UserSignInModal';

function Header_User() {
  const navigate = useNavigate
  const { t } = useTranslation();// ['user', 'common']
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      dispatch(fetchUser());
    }, 60000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [dispatch]);
  const handleSignOut = () => {
    dispatch(clearUser());
  }
  return (
    <div className="">
      {!user.isSignedIn ? (
        <span className='pointer' onClick={e => setShowModal(true)} title={t('Signin')}><HiOutlineLogin size="30" className="text-green" style={{ position: 'relative', top: '1px' }} /></span>
      ) : (
        <>
          {user.type && <Link to={`/${user.type.toLowerCase()}/account`} title={t('MyAccount')}><FiUser size="30" className="text-green" /> </Link>}
          <Link onClick={handleSignOut} title={t('Signout')}><HiOutlineLogout size="30" className="text-green" style={{ position: 'relative', top: '1px' }} /></Link>
        </>
      )}
      <UserSignInModal show={showModal} options={{ onSigned: '/customer/account' }} onClose={e => setShowModal(false)} onSuccess={e => setShowModal(false)} />
    </div>
  );
}
export default Header_User;