import React, { useEffect, useState } from 'react';
import API from './../../api';
import { debug, swalAlert } from './../../utils';
import { Modal, Button } from 'react-bootstrap'
import DeliveryForm from './../deliveries/DeliveryForm'; // Assume que DeliveryForm est un composant d'édition
import { MdEdit, MdAttachMoney } from 'react-icons/md';
const CustomerDeliveryAddresses = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const [search, setSearch] = useState({
    daCompName: '',
    daName: '',
    daPostCode: '',
    daCity: '',
    daStatus: 'visible'
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  useEffect(() => {
    fetchDeliveries();
  }, []);

  useEffect(() => {
    filterDeliveries()
  }, [search, deliveries])

  const fetchDeliveries = async () => {
    try {
      const response = await API.get('/deliveries');
      setDeliveries(response.data);
      setFilteredDeliveries(response.data);
    } catch (error) {
      swalAlert(error.response.data.error.message, "error");
    }
  };
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
    // filterDeliveries({ ...search, [name]: value });
  };
  const filterDeliveries = () => {
    const filtered = deliveries.filter((delivery) =>
      delivery.daCompName.toLowerCase().includes(search.daCompName.toLowerCase()) &&
      delivery.daName.toLowerCase().includes(search.daName.toLowerCase()) &&
      delivery.daPostCode.toLowerCase().includes(search.daPostCode.toLowerCase()) &&
      delivery.daCity.toLowerCase().includes(search.daCity.toLowerCase()) &&
      (search.daStatus == '' || delivery.daStatus == search.daStatus)
    );
    setFilteredDeliveries(filtered);
  };
  const openEditModal = (delivery) => {
    setSelectedDelivery(delivery);
    setShowEditModal(true);
  };


  const handleDeliverySaved = (delivery) => {
    console.log(delivery)
    if (delivery) {
      // Vérifier si l'adresse de livraison existe déjà dans la collection 'deliveries'
      const existingDeliveryIndex = deliveries.findIndex(d => d.daId === delivery.daId);
      alert(existingDeliveryIndex)
      if (existingDeliveryIndex !== -1) {
        // Si l'adresse existe déjà, on met à jour l'existante
        const updatedDeliveries = [...deliveries];
        updatedDeliveries[existingDeliveryIndex] = delivery;
        setDeliveries(updatedDeliveries);
      } else {
        // Si l'adresse n'existe pas, on l'ajoute
        setDeliveries([...deliveries, delivery]);
      }
    }
    closeEditModal();
  }
  const closeEditModal = () => {
    setSelectedDelivery(null);
    setShowEditModal(false);
  };
  return (
    <div className="customer-delivery-addresses">
      <div style={{ maxHeight: '500px', overflowY: 'auto' }} className="table-wrapper">
        <table className="table table-sm table-striped table-bordered mt-3">
          <thead className="sticky-top bg-light"> {/* Utilisation de 'sticky-top' pour rendre l'en-tête sticky */}
            <tr className="">
              <th className="">Société
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="daCompName"
                  name="daCompName"
                  value={search.daCompName}
                  onChange={handleSearch}
                  placeholder=""
                />
              </th>
              <th className="">Nom
                <input
                  type="text"
                  className="form-control form-control-sm"

                  name="daName"
                  value={search.daName}
                  onChange={handleSearch}
                  placeholder=""
                />
              </th>
              <th className="">Code postal
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="daPostCode"
                  value={search.daPostCode}
                  onChange={handleSearch}
                  placeholder=""
                />
              </th>
              <th className="">Ville
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="daCity"
                  value={search.daCity}
                  onChange={handleSearch}
                  placeholder=""
                />
              </th>
              <th className="">Mémorisée
                <select
                  type="text"
                  className="form-control form-control-sm text-center"
                  name="daStatus"
                  value={search.daStatus}
                  onChange={handleSearch}
                  placeholder=""
                >
                  <option value="">Toutes</option>
                  <option value="visible">Oui</option>
                  <option value="hidden">Non</option>
                </select>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredDeliveries.map((delivery) => (
              <tr key={delivery.daId} style={{ opacity: delivery.daStatus == 'visible' ? '1' : '0.5' }}>
                <td>{delivery.daCompName}</td>
                <td>{delivery.daName} {delivery.daFirstname}</td>
                <td className="text-center">{delivery.daPostCode}</td>
                <td>{delivery.daCity}</td>
                <td className="text-center">{delivery.daStatus == 'visible' ? 'oui' : 'non'}</td>
                <td>
                  <span
                    className="text-green pointer"
                    onClick={() => openEditModal(delivery)}
                    title="Paramètres"
                  >
                    <MdEdit size={18} />
                  </span>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showEditModal && (
        <Modal show={showEditModal} onHide={closeEditModal} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>Edition d'adresse</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedDelivery && (
              <DeliveryForm delivery={selectedDelivery} onSave={handleDeliverySaved} onClose={closeEditModal} />
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
export default CustomerDeliveryAddresses;
