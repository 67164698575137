import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import './admin-products-list.css';
import { useParams, useNavigate } from 'react-router-dom';
import Catalog from './../../products/Catalog'
import { FaPlus } from 'react-icons/fa';

import AdminProductModal from './AdminProductModal'

function AdminProductList() {
  const { t } = useTranslation();
  const { id } = useParams(); // Utiliser useParams pour accéder au paramètre :id 
  const navigate = useNavigate(); // Initialiser useNavigate
  const [currentCatalog, setCurrentCatalog] = useState('Faisceau');

  const newProduct = {
    prodId: '',
    prodCatalogue: currentCatalog,
    prodStatut: 'disponible',
    prodPicture: '',
    prodDatasheet: '',

  }
  const [selectedProduct, setSelectedProduct] = useState(id === 'new' ? { ...newProduct } : null);
  const [showProductModal, setShowProductModal] = useState(id === 'new' ? true : false);
  const [lastUpdate, setLastUpdate] = useState(-1)
  const handleProductSelected = (product) => {
    setSelectedProduct(product);
    setShowProductModal(true);
  }

  useEffect(() => {
    if (id === 'new') {
      // Modifier l'URL sans rafraîchir la page
      navigate('/extradmin/products', { replace: true });
    }
  }, [id, navigate]);

  const handleCreateProduct = () => {
    setSelectedProduct({ ...newProduct });
    setShowProductModal(true);
  }

  // Fonction pour notifier un ajout ou une modification
  const handleProductCreated = (product) => {
    console.log('Produit ajouté  :', product);

    setLastUpdate(new Date().getTime());

    // Logique supplémentaire pour mettre à jour l'état ou appeler une API...
  };


  const handleProductUpdated = (product) => {
    setLastUpdate(new Date().getTime());
  };
  const handleCloseModal = () => {
    setShowProductModal(false);
  }
  return (
    <div id='AdminProductList'>
      <div className='container'>
        <div className=" page-title">
          <div className="col-12 col-md-3"></div>
          <div className="col-12 col-md-6">
            <h1 className="page-title">Gestion des produits</h1>
          </div>
          <div className="col-12 col-md-3 text-end ">
            <button className="btn btn-green" onClick={handleCreateProduct}>
              <FaPlus size={14} style={{ top: "-1px" }} className="position-relative me-2" />Ajouter
            </button>
          </div>
        </div>
        <Catalog isAdmin={true} onSelect={handleProductSelected} lastUpdate={lastUpdate} onCatalogChange={cat => setCurrentCatalog(cat)} />
      </div >
      {
        showProductModal &&

        <AdminProductModal product={selectedProduct} onClose={handleCloseModal} onCreated={handleProductCreated} onUpdated={handleProductUpdated} />
      }
    </div >
  );
}
export default AdminProductList
