import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { useTranslation } from 'react-i18next';
import { debug, formatPrice } from '../../../utils';

const AdminProductRelated = ({ product, onChange = null }) => {
  const { t } = useTranslation();
  const [kits, setKits] = useState([]);
  const [related, setRelated] = useState([]);
  const [filter, setFilter] = useState({ prodTitre: '', prodRef: '' });

  useEffect(() => {
    const fetchData = async () => {
      if (product && product.prodRef?.trim() !== '') {
        try {
          // D'abord, on attend la réponse de l'API pour les produits liés
          const relatedResponse = await API.get(`/admin/reference/${product.prodRef}/related`);
          setRelated(relatedResponse.data);

          // Ensuite, on récupère les kits seulement après avoir les produits liés
          const kitsResponse = await API.get('/admin/products?query={"prodCatalogue:eq":"kit"}');
          const kitsData = kitsResponse.data.splice(0, 100000);

          // Séparation en relatedKits et nonRelatedKits
          const relatedKits = kitsData.filter(kit => isRelated(kit.prodRef, relatedResponse.data));
          const nonRelatedKits = kitsData.filter(kit => !isRelated(kit.prodRef, relatedResponse.data));

          // Fusionner les deux tableaux, relatedKits en premier
          setKits([...relatedKits, ...nonRelatedKits]);
        } catch (error) {
          console.log('Erreur lors du chargement des données :', error);
        }
      }
    };

    fetchData();
  }, [product.prodId]);

  // Fonction isRelated avec un paramètre additionnel pour garantir qu'on vérifie sur les données correctes
  const isRelated = (kitRef, relatedData) => {
    return relatedData.some(rel => rel.relRelatedRef === kitRef);
  };

  const handleCheckboxChange = (kitRef, checked) => {
    const relatedCopy = [...related];
    if (checked) {
      relatedCopy.push({ relRef: product.prodRef, relRelatedRef: kitRef, relStatus: 'active' });
    } else {
      const index = relatedCopy.findIndex(rel => rel.relRelatedRef === kitRef);
      if (index !== -1) {
        relatedCopy.splice(index, 1); // Supprime l'élément trouvé dans la copie
      }
    }
    setRelated(relatedCopy); // Mets à jour l'état avec la copie modifiée
    if (onChange) {
      onChange(relatedCopy);
    }
  };

  const normalizeText = (text) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  const filteredKits = kits.filter(kit =>
    normalizeText(kit.prodTitre).includes(normalizeText(filter.prodTitre)) &&
    normalizeText(kit.prodRef).startsWith(normalizeText(filter.prodRef))
  );

  return (
    <div id="AdminProductRelated">
      <table className="table table-striped table-hover table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col" className="w-25">
              {t('prodRef')}
              <input
                type="text"
                className="form-control form-control-sm text-center"
                placeholder={t('Filtrer par référence')}
                value={filter.prodRef}
                onChange={(e) => setFilter({ ...filter, prodRef: e.target.value })}
              />
            </th>
            <th scope="col">
              {t('prodTitre')}
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('Filtrer par nom')}
                value={filter.prodTitre}
                onChange={(e) => setFilter({ ...filter, prodTitre: e.target.value })}
              />
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {filteredKits.map((kit, index) => (
            <tr key={index}>
              <td className="text-center">{kit.prodRef}</td>
              <td className="">
                <div className="wordings">
                  {kit.prodTitre}
                  <div className="prodMemo">{kit.prodMemo}</div>
                </div>
              </td>
              <td>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isRelated(kit.prodRef, related)}
                  onChange={(e) => handleCheckboxChange(kit.prodRef, e.target.checked)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {debug(related, false)}
      {debug(product, false)}
    </div>
  );
};

export default AdminProductRelated;
