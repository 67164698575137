import React, { useEffect, useState } from 'react';
import API from './../../api';
import { debug, swalAlert } from './../../utils';
// import './customer-account.css'; // Si tu souhaites ajouter du style
import InputPassword from './../helpers/InputPassword/InputPassword';
import PasswordChecker from './../helpers/PasswordChecker/PasswordChecker';

const CustomerPassword = () => {
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordsAreSimilar, setPasswordsAreSimilar] = useState(true);
  const [data, setData] = useState({ currentPassword: '', password: '', passwordConfirm: '' })
  useEffect(() => {
    if (data.passwordConfirm != '') {
      setPasswordsAreSimilar(data.password == data.passwordConfirm)
    }
  }, [data])
  const handleSubmit = async (e) => {
    try {
      API.handleError = false;
      await API.put('/customer/password', data);
      swalAlert("Le mot de passe a été mis à jour", "success");
      setData({ currentPassword: '', password: '', passwordConfirm: '' });
    } catch (error) {
      swalAlert(error.response.data.error.message, "error");
    }
  };
  return (
    <div className="customer-password">
      <form className="px-3" >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-group-label">Mot de passe actuel</label>
              <InputPassword
                value={data.currentPassword}
                onChange={value => setData({ ...data, 'currentPassword': value })}
                inputClassName='form-control-sm' />
            </div>
            <div className="form-group">
              <label className="form-group-label">Nouveau mot de passe</label>
              <InputPassword
                value={data.password}
                onChange={value => setData({ ...data, 'password': value })}
                inputClassName='form-control-sm' />
            </div>
            <div className="form-group">
              <label className="form-group-label">
                Confirmation du mot de passe
              </label>
              <InputPassword
                value={data.passwordConfirm}
                onChange={value => setData({ ...data, 'passwordConfirm': value })}
                inputClassName='form-control-sm'
              />
              {(!passwordsAreSimilar) && <span className="fs-8 text-danger fw-normal">Les mots de passe ne sont pas identiques</span>}
            </div>
            <PasswordChecker password={data.password} isValid={(value) => { setPasswordIsValid(value) }} />
            <button
              type="button"
              className="btn btn-green"
              disabled={data.currentPassword == '' || data.password == '' || data.passwordConfirm == '' || !passwordIsValid || !passwordsAreSimilar}
              onClick={handleSubmit}>Sauvegarder</button>
          </div>
        </div>
        {debug(data)}
      </form>
    </div>
  );
};

export default CustomerPassword;
