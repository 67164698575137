import React, { useState, useEffect } from 'react';
import ProductSearchBar from './../products/ProductSearchBar';
import ProductModal from '../products/ProductModal';
import FeaturedProducts from './../products/FeaturedProducts';
import LastProducts from './../products/LastProducts';
import { useSelector, useDispatch } from 'react-redux';
import './home-main.css';
import { debug } from './../../utils';
const HomeMain = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const user = useSelector((state) => state.user);
  const [showProductModal, setShowProductModal] = useState(false);
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };
  useEffect(() => {
    if (selectedProduct) {
      setShowProductModal(true);
    }
  }, [selectedProduct]);

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowProductModal(false);
  };

  return (
    <div id="home-main">
      <div className="page-title">
        <h1>Bienvenue dans l'extranet clients</h1>
      </div>
      <div className='top-section'>
        <ProductSearchBar onSelect={handleProductSelect} />
      </div>
      <div className='center-section pt-3'>
        <div className="container">
          <div className="row">
            {user && user?.catalogs?.includes('Kit') &&
              <div className="col col-md-8 col-12 mb-5 py-2">
                <div className="module">

                  <FeaturedProducts onSelectProduct={handleProductSelect} />
                </div>
              </div>
            }
            <div className="col col-md-4 col-12 mb-5 pt-2">
              <div className="module">
                <LastProducts onSelectProduct={handleProductSelect} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showProductModal && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
    </div>
  );
};

export default HomeMain;
