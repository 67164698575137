import React, { useState } from 'react';
import ResponsiveNavTabs from './../helpers/ResponsiveNavTabs/ResponsiveNavTabs';
import CustomerProfil from './CustomerProfil';
import CustomerPassword from './CustomerPassword';
import CustomerDeliveryAddresses from './CustomerDeliveryAddresses';
import 'bootstrap/dist/css/bootstrap.min.css';
import './customer-account.css';
const CustomerAccount = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="customer-account">
      <div className="page-title">
        <h1>Mon compte</h1>
      </div>
      <div className="container ">
        <div className="row">
          <div className={`${activeTab == 2 ? 'col-xl-12' : 'col-xl-8'} col-lg-12 col-md-12`}>
            <ResponsiveNavTabs activeTab={activeTab} tabNames={['Profil', ' Mot de passse', 'Adresses de livraison']} setActiveTab={setActiveTab} />
            <div className="border border-top-0 rounded-bottom pt-4 mt-0 pb-3">
              {activeTab == 0 && <CustomerProfil />}
              {activeTab == 1 && <CustomerPassword />}
              {activeTab == 2 && <CustomerDeliveryAddresses />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerAccount;
