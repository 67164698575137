import React, { useEffect, useState, useMemo, useRef } from 'react';
import API from './../../../api';
import { useTranslation } from 'react-i18next';

import AdminCustomerModal from './../../customers/admin/AdminCustomerModal';

import { formatDate, formatPrice, download } from '../../../utils';

import { CiViewList } from "react-icons/ci";
import './admin-orders-list.css';
function AdminOrdersList({
  year_default = '',
  limit = false,
  title = true,
  selector = true,
  thead = true,
  columns = false,
  query = {}
}) {
  const { t } = useTranslation();
  const [year, setYear] = useState(year_default !== '' ? year_default : new Date().getFullYear());
  const [yearOrders, setYearOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    orderRef: '',
    client: '',
    prodRef: '',
    delivery: ''
  });
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(currentYear - 2016 + 1), (val, index) => 2016 + index).reverse();
  // Pagination progressive
  const [batchSize] = useState(50); // Nombre de lignes à afficher à chaque fois
  const [visibleRows, setVisibleRows] = useState([]);
  const tableBodyRef = useRef(null);
  useEffect(() => {
    setLoading(true);
    getOrders();
  }, [year]);
  useEffect(() => {
    //     setLoading(true);
    //     getOrders();
  }, [yearOrders]);
  const getOrders = async () => {
    const $query = { ...query }
    if (query['year:eq'] == undefined && selector === true) {
      $query['year:eq'] = year;
    }
    /*
    if (Object.keys[$query].length === 0) {
      $query['year:eq'] = year;
    }
      */
    console.log($query, limit)
    try {
      let url = '/admin/orders?query=' + JSON.stringify($query) + (limit ? '&limit=' + limit.toString() : '');
      // console.log(url); return;
      const response = await API.get(url);
      setYearOrders(response.data);
      setVisibleRows(response.data.slice(0, batchSize)); // Commencer avec un premier batch
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // Gestion du scroll pour afficher plus de lignes au fur et à mesure
  const handleScroll = () => {
    if (tableBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setVisibleRows(prevRows => {
          const nextBatch = yearOrders.slice(prevRows.length, prevRows.length + batchSize);
          return [...prevRows, ...nextBatch];
        });
      }
    }
  };
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };
  const filteredOrders = useMemo(() => {
    return visibleRows.filter(order => {
      const preComputedClient = `${order.customer.userCompName} ${order.customer.userName}`.toLowerCase();
      const preComputedDelivery = order.delivery ? `${order.delivery.daCompName} ${order.delivery.daCity}`.toLowerCase() : '';
      const numberMatches = order.orderRef.startsWith(filters.orderRef.toLowerCase()) || order.orderInternalRef.toLowerCase().startsWith(filters.orderRef.toLowerCase());
      const clientMatches = preComputedClient.includes(filters.client.toLowerCase());
      const prodRefMatches = order.items.some(item => item.itProdRef.includes(filters.prodRef));
      const deliveryMatches = preComputedDelivery.includes(filters.delivery.toLowerCase());
      return numberMatches && clientMatches && prodRefMatches && deliveryMatches;
    });
  }, [visibleRows, filters]);
  /* CUSTOMER MODAL */
  const [customerModalContent, setCustomerModalContent] = useState('');
  const [editedCustomer, setEditedCustomer] = useState(null);
  const handleCustomerModalHide = () => {
    setEditedCustomer(null);
  }
  const handleCustomerCreated = () => { }
  const handleCustomerUpdated = (customer) => {
    const updatedOrders = yearOrders.map(order => {
      if (order.orderUserId === customer.userId) {

        return {
          ...order,
          customer: {
            ...order.customer,
            userCompName: customer.userCompName,
            userName: customer.userName,
            userFirstname: customer.userFirstname
          }
        };
      }
      return order;
    });
    setYearOrders(updatedOrders);
    setEditedCustomer(null);
    setVisibleRows(updatedOrders.slice(0, visibleRows.length)); // pour rafraichier l'affichage
  };
  const handleEditCustomer = async (custId) => {
    const response = await API.get(`/admin/customer/${custId}`);
    setEditedCustomer(response.data);
    return;
  };
  useEffect(() => {
    if (editedCustomer !== null) {
      setCustomerModalContent('customer');
    } else {
      setCustomerModalContent('');
    }
  }, [editedCustomer])
  return (
    <div id="AdminOrdersList">
      <div className="container">
        {title === true &&
          <div className="page-title">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6">
              <h1 className="page-title">  {t('Orders list')}</h1>
            </div>
            <div className="col-12 col-md-3 text-end fw-bold">
              {!loading && <span className="ms-2 pt-2">{formatPrice(yearOrders.length, 0)} Cdes</span>}
            </div>
          </div>
        }
        {loading ? (
          <div className="loading-indicator text-center my-3">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
            <p>{t('Chargement en cours...')}</p>
          </div>
        ) : (
          <>
            {selector && (
              <div className="row">
                <div className="col-md-3">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <label className="input-group-text" htmlFor="yearSelect" style={{ borderRadius: "0.375rem", background: "var(--green)", color: "#fff" }}>
                        {t('Année')}
                      </label>
                    </div>
                    <select
                      id="yearSelect"
                      className="form-control"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      {years.map((yearOption) => (
                        <option key={yearOption} value={yearOption}>
                          {yearOption}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className="table-container" onScroll={handleScroll} ref={tableBodyRef} >
              <table className="table table-sm table-striped">
                {thead && (
                  <thead>
                    <tr>
                      {(columns === false || columns.includes('orderDate')) &&
                        <th>{t('Date')}</th>
                      }
                      {(columns === false || columns.includes('orderRef')) &&
                        <th>
                          <input
                            type="text"
                            className="form-control form-control-sm text-center"
                            placeholder={t('orderRef')}
                            name="orderRef"
                            value={filters.orderRef}
                            onChange={handleFilterChange}
                          />
                        </th>
                      }
                      {(columns === false || columns.includes('customer')) &&
                        <th>
                          <input
                            type="text"
                            className="form-control form-control-sm text-center"
                            placeholder={t('Customer')}
                            name="client"
                            value={filters.client}
                            onChange={handleFilterChange}
                          />
                        </th>
                      }
                      {(columns === false || columns.includes('articles')) &&
                        <th>
                          <input
                            type="text"
                            className="form-control form-control-sm text-center"
                            placeholder={t('prodRefs')}
                            name="prodRef"
                            value={filters.prodRef}
                            onChange={handleFilterChange}
                          />
                        </th>}
                      {(columns === false || columns.includes('delivery')) &&
                        <th>
                          <input
                            type="text"
                            className="form-control form-control-sm text-center"
                            placeholder={t('Delivery')}
                            name="delivery"
                            value={filters.delivery}
                            onChange={handleFilterChange}
                          />
                        </th>
                      }
                      {(columns === false || columns.includes('orderTotalPriceIT')) &&
                        <th>{t('orderTotalPriceIT')}</th>
                      }
                      {(columns == false || columns.includes('download')) &&
                        <th style={{ width: '80px' }}></th>
                      }
                    </tr>

                  </thead>
                )}
                <tbody>
                  {filteredOrders.map((order, index) => (
                    <tr key={order.orderId}>
                      {(columns === false || columns.includes('orderDate')) &&
                        <td className='text-center'>{formatDate(order.orderDate)}</td>
                      }
                      {(columns === false || columns.includes('orderRef')) &&
                        <td className='text-center'>{order.orderRef}<br />{order.orderInternalRef}</td>
                      }
                      {(columns === false || columns.includes('customer')) &&
                        <td className='text-center'>
                          <span onClick={e => handleEditCustomer(order.orderUserId)} className="text-green text-decoration-underline pointer">
                            {order.customer.userCompName != '' ? order.customer.userCompName : <>{order.customer.userName} {order.customer.userFirstname}</>}
                          </span>
                        </td>
                      }
                      {(columns === false || columns.includes('articles')) &&
                        <td className="text-center">{order.items.map((item, key) => (
                          <span key={key} onClick={e => download(`/admin/order/item/${item.itId}/datasheet`)} className="pointer text-decoration-underline text-green">
                            {item.itProdRef} X {item.itQuantity}<br />
                          </span>))}
                        </td>
                      }
                      {(columns === false || columns.includes('delivery')) &&
                        <td className="text-center">
                          {order.delivery && <span >
                            {order.delivery.daCompName !== '' ?
                              <>{order.delivery.daCompName}</>
                              :
                              <>{order.delivery.daName}  {order.delivery.daFirstname}</>
                            }
                          </span>
                          }
                        </td>
                      }
                      {(columns === false || columns.includes('orderTotalPriceIT')) &&
                        <td className="text-center">{formatPrice(order.orderTotalPriceIT)}</td>
                      }
                      {(columns == false || columns.includes('download')) &&
                        <td className="text-center">
                          <a onClick={e => download(`/admin/order/pdf/${order.orderId}`, t)}>
                            <CiViewList size="24" className="text-green" />
                          </a>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>


      {(customerModalContent !== '' && editedCustomer) && (
        <AdminCustomerModal

          onHide={handleCustomerModalHide}
          modalContent={customerModalContent}
          editedCustomer={editedCustomer}
          handleCustomerCreated={handleCustomerCreated}
          handleCustomerUpdated={handleCustomerUpdated}
        />
      )}


    </div>
  );
}

export default AdminOrdersList;


