
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import './products-list-header.css';
const ProductsListHeader = ({ catalog, featuredProductOnly = false, toggleFeaturedProductOnly = () => { } }) => {
  const { t } = useTranslation();
  return (
    <thead className='products-list-header fw-bold'>
      <tr>
        {catalog.toLowerCase() != 'kit' ?
          <th className='type  fw-bold'>Premium <br />Standard</th>
          : <th className='cover  fw-bold'>
            {/*<FaStar
            className="featured pointer"
            title="Produits phares"
            style={{ color: featuredProductOnly ? 'yellow' : 'gray' }}
            onClick={toggleFeaturedProductOnly} />
            */}
          </th>
        }

        <th className='ref fw-bold'>{t('Ref')}</th>
        <th className='constructor fw-bold'>
          {catalog != 'Kit' ?
            <>{t('Constructor')}<br />{t('Model')}</>
            :
            <>  {t('Description')}</>
          }
        </th>
        {catalog.toLowerCase() != 'kit' &&
          <th className='years  fw-bold'>{t('Years')}</th>
        }
        {catalog.toLowerCase() != 'kit' && <th className='type'>{t('Type')}</th>}
        <th className='price  fw-bold'>{t('HT')} €</th>
        <th className='stocks  fw-bold'>{t('Stocks')}</th>
        <th className='functions'></th>
      </tr>
    </thead>)
}
export default ProductsListHeader;