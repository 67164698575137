// AuthGuard.js

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Importe useSelector
import { useLocation, Navigate } from 'react-router-dom';
const AuthGuard = ({ children }) => {
  const user = useSelector((state) => state.user);
  const location = useLocation;
  if (!user.isSignedIn) {
    return <Navigate to={"/"} replace />;
  }
  return <>{children}</>;
};
export default AuthGuard;