// DeliveriesListItem.js
import { useState, useEffect } from 'react'; import { useTranslation } from 'react-i18next';
import API from './../../api';
import './../../css/forms.css';
import 'sweetalert2/dist/sweetalert2.min.css';
function DeliveryForm({ delivery, onSave }) {
  const [address, setAddress] = useState({
    daId: null,
    daCompName: 'Anima Net',
    daName: 'Picard',//
    daFirstname: 'Vincent',
    daAddress: 'Rue Malatiret',
    daPostCode: '76000',
    daCity: 'Rouen',
    daPhone1: '06 07 23 36 75',
    daPhone2: '',
    daEmail: 'gestion@animanet.fr',
    daCompName: '',
    daName: '',//
    daFirstname: '',
    daAddress: '',
    daPostCode: '',
    daCity: '',
    daCountry: 'FRANCE',
    daPhone1: '',
    daPhone2: '',
    daEmail: '',
    ...delivery,
  });
  useEffect(() => {
    try {
    } catch (e) {
    }
  }, []);
  const { t } = useTranslation();
  const handleChange = (event) => {
    let value;
    switch (event.target.name) {
      case 'daStatus':
        setAddress({ ...address, daStatus: event.target.checked ? 'visible' : 'hidden' });
        break;
      default:
        setAddress({ ...address, [event.target.name]: event.target.value });
    }
  }
  const [errorMessage, setErrorMessage] = useState('');
  const handleFormSubmit = async (event) => {
    setErrorMessage('');
    event.preventDefault();
    try {
      API.handleError = false;
      alert(address.daId)
      const response = address.daId
        ? await API.put('/delivery/' + address.daId, address)
        : await API.post('/delivery', address);
      onSave(response.data);
    } catch (error) {
      console.log(error)
      if (error.response.data.error.code == 422) {
        const parts = error.response.data.error.message.split(':');
        let errorMsg = t(parts[0]) + ' : ' + "\n";
        JSON.parse(parts[1]).forEach(err => {
          errorMsg += '- ' + t(err) + "\n";
        })
        setErrorMessage(errorMsg);
        console.log(parts)
      }
    }
  }
  return (
    <div className="delivry-form">
      <form noValidate>
        <div className="form-group mb-2 daCompName">
          <label className='form-group-label'>{t('daCompName')} : </label>
          <input type="text" className="form-control form-control-sm daCompName" name="daCompName" value={address.daCompName} onChange={handleChange} />
        </div>
        <div className="form-group mb-2 daName">
          <label className='form-group-label'>{t('daName')}  : <span className="required"></span></label>
          <input type="text" className="form-control form-control-sm daName" name="daName" value={address.daName} onChange={handleChange} />
        </div>
        <div className="form-group mb-2 daFirstname">
          <label className='form-group-label'>{t('daFirstname')}  : <span className="required"></span></label>
          <input type="text" className="form-control form-control-sm daFirstname" name="daFirstname" value={address.daFirstname} onChange={handleChange} />
        </div>
        <div className="form-group mb-2 daAddress">
          <label className='form-group-label'>{t('daAddress')}  :<span className="required"></span></label>
          <textarea className="form-control form-control-sm daAddress" name="daAddress" value={address.daAddress} onChange={handleChange}></textarea>
        </div>
        <div className='row'>
          <div className='col-sm-4'>
            <div className="form-group mb-2 daPostCode">
              <label className='form-group-label'>{t('daPostCode')}  :<span className="required"></span></label>
              <input type="text" className="form-control form-control-sm daPostCode" name="daPostCode" value={address.daPostCode} onChange={handleChange} />
            </div>
          </div>
          <div className='col-sm-8'>
            <div className="form-group mb-2 daCity">
              <label className='form-group-label'>{t('daCity')}  :<span className="required"></span></label>
              <input type="text" className="form-control form-control-sm daCity" name="daCity" value={address.daCity} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="form-group mb-2 daCountry">
          <label className='form-group-label'>{t('daCountry')}  :<span className="required"></span></label>
          <input type="text" className="form-control form-control-sm daCountry" name="daCountry" value={address.daCountry} onChange={handleChange} />
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className="form-group mb-2 daPhone1 daPhone">
              <label className='form-group-label'>{t('daPhone1')} :<span className="required"></span></label>
              <input type="text" className="form-control form-control-sm daPhone1" name="daPhone1" value={address.daPhone1} onChange={handleChange} />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className="form-group mb-2 daPhone2 daPhone">
              <label className='form-group-label'>{t('daPhone2')}  :</label>
              <input type="text" className="form-control form-control-sm daPhone2" name="daPhone2" value={address.daPhone2} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="form-group mb-3 daEmail daEmail">
          <label className='form-group-label'>{t('daEmail')}  :<span className="required"> </span></label>
          <input type="text" className="form-control form-control-sm daEmail" name="daEmail" value={address.daEmail} onChange={handleChange} />
        </div>
        <div className="form-group mb-4 daStatus">
          <label className='checkbox-label'>
            <input type="checkbox" name="daStatus" checked={address.daStatus == 'visible'} onChange={handleChange} />
            Mémoriser cette adresse pour des commandes futures
          </label>
        </div>
        {errorMessage != '' && <div className='alert alert-danger alert-form-error mb-4' >{errorMessage}</div>}
        <div className="form-group mb-3 text-start">
          <button className='btn btn-green btn-sm' onClick={handleFormSubmit} type="button">
            Sauvegarder
          </button>
        </div>
      </form>
    </div >
  );
}
export default DeliveryForm;
/*      {false && <pre>{JSON.stringify(address, null, 2)}</pre>} */