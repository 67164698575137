// AuthGuard.js

import React, { useState, useEffect } from 'react';
import API from './../api';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import { updateUser } from './../redux/userSlice';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
const AdminGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    API.get('/admin/user').then(response => {
      dispatch(updateUser(response.data));
      setIsReady(true);
    }).catch(error => {
      navigate('/')
    });

  }, [location]);
  return <>
    {isReady &&
      (user.isAdmin ? <> {children} </> : <Navigate to="/" />)
    }

  </>;
};
export default AdminGuard;