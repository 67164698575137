import { useState, useEffect, useRef } from 'react';
import API from './../../../api';
import InputAutocomplete from './../../helpers/InputAutocomplete/InputAutocomplete';
import AdminProductFeaturesFaisceau from './AdminProductFeaturesFaisceau';
import AdminProductFeaturesAttelage from './AdminProductFeaturesAttelage';
import AdminProductFeaturesKit from './AdminProductFeaturesKit';
import Uploader from './../../helpers/uploader/Uploader'
import Picture from './../../helpers/Picture/Picture'
import { debug, download, pathInfo, file } from '../../../utils';
const AdminProductFeatures = ({ product, onChange }) => {
  const [constructors, setConstructors] = useState([]);
  const suggestMarques = useRef(null);
  const uploaderInputProps = { accept: '.pdf', multiple: false }
  useEffect(() => {
    if (product.prodCatalogue) {
      API.get('/admin/products/constructors?prodCatalogue=' + product.prodCatalogue).then(response => {
        const uniqueConstructors = Array.from(new Set(response.data.map(item => item.trim())))
          .map(name => ({ name }));
        setConstructors(uniqueConstructors);
      });
    }
  }, [product.prodCatalogue]);
  useEffect(() => { }, [product])
  const handleChange = (event) => {
    console.log(event.target.name)
    onChange({ [event.target.name]: event.target.value });
  }
  const onUploadStart = () => { }
  const onUploadSuccess = (what, data) => {
    onChange({ [what]: data.path });
  }
  const onUploadFailure = () => { }
  return (
    <div id="AdminProductFeatures">
      <div className='form-group' style={{ height: '60px' }}>
        <label className='form-group-label' required >Catalogue : </label>
        <div>
          <label className='radio-label me-3'>
            Faisceaux -
            <input type='radio' name="prodCatalogue" onChange={handleChange} value="Faisceau" checked={product.prodCatalogue === 'Faisceau'} />
          </label>
          <label className='radio-label  me-3'>
            Kits
            <input type='radio' name="prodCatalogue" onChange={handleChange} value="Kit" checked={product.prodCatalogue === 'Kit'} />
          </label>
          <label className='radio-label me-3'>
            Attelages
            <input type='radio' name="prodCatalogue" onChange={handleChange} value="Attelage" checked={product.prodCatalogue === 'Attelage'} />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6  pe-3'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label className='form-group-label' required >Référence : </label>
                <input type='text' className='form-control form-control-sm' name="prodRef" onChange={handleChange} value={product.prodRef} />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className="form-group">
                <label className='form-group-label  ' >Code EAN : </label>
                <input type='text' className='form-control form-control-sm' name="prodCodeEAN" onChange={handleChange} value={product.prodCodeEAN} />
              </div>
            </div>
          </div>

          {product.prodCatalogue !== 'Kit' &&
            <div div className='row'>
              <div className='col-sm-6'>
                <div className='form-group'>
                  <label className='form-group-label' >Constructeur : </label>
                  <InputAutocomplete
                    inputRef={suggestMarques}
                    inputClassName='form-control-sm'
                    disabled={false}
                    readonly={false}
                    suggestionsSrc={constructors}
                    name='prodMarque'
                    placeholder=''
                    value={product.prodMarque}
                    displayKey="name"
                    onChange={handleChange}
                    handleOnSuggestsChange={e => { }}
                    formatSuggestion={(key, constructor) => constructor.name}
                    onSuggestionSelect={(key, value) => onChange({ [key]: value.name })}
                    debug={false} />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='form-group'>
                  <label className='form-group-label required ' >Modèle : </label>
                  <input type='text' className='form-control form-control-sm' name="prodModele" onChange={handleChange} value={product.prodModele} />
                </div>
              </div>
            </div>
          }
          {product.prodCatalogue?.toLowerCase() !== 'kit' ?
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-group'>
                  <label className='form-group-label  ' >Années : </label>
                  <input type='text' className='form-control form-control-sm' name="prodAnnee" onChange={handleChange} value={product.prodAnnee} />
                </div>
              </div>
              <div className='col-sm-6'>
              </div>
            </div>
            :
            <div className='form-group'>
              <label className='form-group-label  ' >Photo : </label>
              <div className="px-2 py-1 border rounded">
                <div className="d-flex flex-row flex-wrap">
                  <div className="prodPicture flex-grow-0 position-relative">

                    {product.prodPicture && product.prodPicture !== '' ?
                      <>
                        <Picture src={product.prodPicture} />
                        <span
                          class="close position-absolute fs-4 pointer"
                          style={{ right: '-20px', top: '-3px', lineHeight: 1, 'zIndex': '20' }}
                          onClick={e => handleChange({ target: { name: 'prodPicture', value: '' } })}
                        >&times;</span>
                      </>
                      :
                      <img src="https://placehold.co/320x240?text=Aucune%20image" />
                    }
                  </div>
                  <div className="flex-grow-1 ps-2 d-flex flex-wrap justify-content-end">
                    <Uploader
                      inputProps={{ accept: 'image/jpeg, image/png, image/webp' }}
                      onUploadStart={data => onUploadStart('prodPicture', data)}
                      onUploadSuccess={data => onUploadSuccess('prodPicture', data)}
                      onUploadFailure={data => onUploadFailure('prodDatasheet', data)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className='col-sm-6'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label className='form-group-label' required >Prix : </label>
                <div className="input-group input-group-sm">
                  <input type='number' className='form-control' name="prodPrix" onChange={handleChange} value={product.prodPrix} />
                  <span className="input-group-text">EUR</span>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className="form-group">
                <label className='form-group-label' required >Statut : </label>
                <select className="form-control form-control-sm" name="prodStatut" value={product.prodStatut} onChange={handleChange}>
                  <option value=""></option>
                  <option value="disponible">Disponible</option>
                  <option value="non disponible">Non disponible</option>
                  <option value="supprimé">Produit à supprimer</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label className='form-group-label' required >Inclure aux newsletters : </label>
                <select className="form-control form-control-sm" name="prodNewsletters" value={product.prodNewsletters} onChange={handleChange}>
                  <option value="0">Non</option>
                  <option value="1">Oui</option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <label className='form-group-label' >Fiche technique : </label>
            <div className="d-flex justify-content-between align-items-center px-2 py-1 border rounded" >
              <div className='small  flex-grow-1' >
                {
                  product.prodDatasheet && product.prodDatasheet != '' ?
                    pathInfo(product.prodDatasheet, 'dirname') != '/tmp' ?
                      <a onClick={e => download(`/product/datasheet/${product.prodId}`)} className="text-decoration-underline" >
                        Voir la fiche technique
                      </a> :
                      <a onClick={e => file(product.prodDatasheet, true)} className="text-decoration-underline" >
                        Voir la fiche technique téléchargée
                      </a> :
                    <>Aucune fiche envoyée</>
                }
              </div>
              <div className="text-end flex-grow-0">
                <Uploader
                  inputProps={{ accept: 'application/pdf', multipe: false, maxSize: 10 * 1024 * 1024, }}
                  onUploadStart={data => onUploadStart('prodDatasheet', data)}
                  onUploadSuccess={data => onUploadSuccess('prodDatasheet', data)}
                  onUploadFailure={data => onUploadFailure('prodDatasheet', data)}
                />
              </div>
            </div>
          </div>
        </div>
        {
          product.prodCatalogue == 'Faisceau' &&
          <AdminProductFeaturesFaisceau product={product} onChange={handleChange} />
        }
        {
          product.prodCatalogue?.toLowerCase() == 'kit' &&
          <AdminProductFeaturesKit product={product} onChange={handleChange} />
        }
        {
          product.prodCatalogue == 'Attelage' &&
          <AdminProductFeaturesAttelage product={product} onChange={handleChange} />
        }
      </div>
    </div>
  )
}
export default AdminProductFeatures;
