import { useState, useEffect } from 'react';
import { IoOpenOutline } from "react-icons/io5";
import API from './../../api';

const LastProducts = ({ onSelectProduct }) => {
  const [lastProducts, setLastProducts] = useState([]);

  useEffect(() => {
    fetchLastProducts();
  }, []);

  const fetchLastProducts = async () => {
    try {
      API.handleError = false;
      const response = await API.get(`/products/last`);
      setLastProducts(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div id="last-products">
      <h2 className="bg-gray-c py-2 px-3" onClick={fetchLastProducts}>Nouveaux produits :</h2>
      <div className="products">
        <table className="table table-sm table-striped">
          <tbody style={{ lineHeight: '1' }} className="fs-9">
            {lastProducts.map(product => (
              <tr key={product.prodId} onClick={() => onSelectProduct(product)}>
                <td>{product.prodRef}</td>
                <td>
                  <strong>{product.prodMarque}</strong>
                  <br />
                  {`${product.prodModele} ${product.prodAnnee}`}
                </td>
                <td className="text-end fs-6">
                  <IoOpenOutline className="text-green pointer" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LastProducts;
