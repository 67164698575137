import { useEffect } from 'react';

import AdminGuard from './AdminGuard';
import './adminLayout.css';
const AdminLayout = ({ children }) => {

  useEffect(() => {
    document.body.classList.add('admin-layout');
    return () => {
      document.body.classList.remove('admin-layout');
    };
  }, []);
  return (
    <>
      <div>
        <AdminGuard>
          <div className="admin-layout">
            {children}
          </div>
        </AdminGuard>
      </div>

    </>
  );
};
export default AdminLayout;