import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

import { debug, formatPrice, download, swalConfirm } from './../../../utils';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import ProdCodeStock from './../ProdCodeStock';
import AdminProductFeatures from './AdminProductFeatures';
import AdminProductSpecialPrices from './AdminProductSpecialPrices';
import AdminProductRelated from './AdminProductRelated';
import Modal from 'react-bootstrap/Modal';
import API from '../../../api'
import './../../../css/modals.css';
import './../../../css/forms.css';
import './../product-modal.css';
import './admin-product.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { IoCaretBackSharp } from "react-icons/io5";
import { CiViewList } from "react-icons/ci";
const AdminProductModal = ({ product, onClose, onCreated, onUpdated }) => { // onUpdated = () => { }
  const [modalProduct, setModalProduct] = useState(null);
  const [formStatus, setFormStatus] = useState('');
  const [activeTab, setActiveTab] = useState('features');
  useEffect(() => {
    get_product();
  }, [product]);
  const get_product = async () => {
    if (product?.prodId) {
      try {
        const response = await API.get(`/admin/product/${product.prodId}`);


        setModalProduct({ ...response.data });
      } catch (e) {
      }
    } else {
      setModalProduct({ ...product });
    }
  }
  const handleProductChange = (tab, payload) => {

    console.log(tab, payload)

    switch (tab) {
      case 'specialPrices':
        setModalProduct({ ...modalProduct, specialPrices: payload });
        break;
      case 'related':
        setModalProduct({ ...modalProduct, related: payload });
        break;
      default:
        setModalProduct({ ...modalProduct, ...payload })
    }
  }
  const handleSave = async (event) => {
    const isNew = product.prodId === '';
    try {
      const response = isNew
        ? await API.post(`/admin/product`, modalProduct) // Si prodId est vide, POST
        : await API.put(`/admin/product/${product.prodId}`, modalProduct) // Si prodId est défini, PUT

      const result = await swalConfirm('Sauvegarde effectuée.', {
        confirmText: 'Fermer la fenêtre',
        cancelText: 'Continuer les modifications',
      });
      if (result === true) { onClose() }
      setModalProduct({ ...response.data });
      if (isNew) {
        onCreated({ ...response.data });
      } else {
        onUpdated({ ...response.data })
      };
    } catch (e) {
    }
  }
  return (
    <Modal show={true} onHide={onClose} className="product-modal" keyboard={true} >
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-8'>
            <Modal.Title>
              {modalProduct?.prodId !== '' ?
                <>Réf : {modalProduct?.prodRef}</>
                :
                <>Nouveau produit</>
              }
            </Modal.Title>
          </div>
          <div className='col-sm-4 text-end pe-4'>
          </div>
        </div>
      </Modal.Header>
      {modalProduct &&
        <Modal.Body className="">
          <ul className='nav nav-tabs'>
            <li className='nav-item'><a className={`nav-link ${activeTab === 'features' ? 'active' : ''}`} onClick={e => setActiveTab('features')}>Caractéristiques <span className='required-0'></span></a></li>
            {modalProduct?.prodId !== '' &&
              <>
                <li className='nav-item'><a className={`nav-link ${activeTab === 'specialPrices' ? 'active' : ''}`} onClick={e => setActiveTab('specialPrices')}>Prix spéciaux</a></li>
                <li className='nav-item'><a className={`nav-link ${activeTab === 'related' ? 'active' : ''}`} onClick={e => setActiveTab('related')}>Accessoires & kits</a></li>
              </>
            }
            <li className='nav-item ms-auto'>
              <button className='btn btn-green' onClick={handleSave} disabled={false} >
                {formStatus == 'saving' && <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
                Sauvegader
              </button>
            </li>

          </ul>
          <div className=' tab-contents pt-3'>
            {activeTab === 'features' && <AdminProductFeatures product={modalProduct} onChange={prod => handleProductChange('features', prod)} />}
            {activeTab === 'specialPrices' && <AdminProductSpecialPrices product={modalProduct} onChange={data => handleProductChange('specialPrices', data)} />}
            {activeTab === 'related' && <AdminProductRelated product={modalProduct} onChange={data => handleProductChange('related', data)} />}
          </div>
          {debug(modalProduct, false)}
        </Modal.Body>
      }
      <Modal.Footer>
      </Modal.Footer>
    </Modal >
  )
}
export default AdminProductModal;
