import React, { useState, useEffect, useRef } from 'react';
import { IoOpenOutline } from "react-icons/io5";
import { formatPrice } from './../../utils';
import { FaStar } from 'react-icons/fa';
import ProdCodeStock from './ProdCodeStock';
import ProdType from './ProdType';
import './products-list-item.css';
import Picture from '../helpers/Picture/Picture';
const ProductListItem = ({ product, isSelected, onSelect, isAdmin }) => {
  {
    return (
      <tr className={`products-list-item ${product.prodStatut.replace(' ', '-')} ${isSelected ? 'selected' : ''} `} onClick={e => onSelect(product)}>
        <td className='prodType text-center  pt-2'>
          {product.prodCatalogue !== 'Kit' ? (
            <ProdType type={product.prodType} />
          ) : (
            <div className="cover">
              {product.prodFeatured === '1' && <FaStar className="featured" title="Produit phare" />}
              {product.prodPicture != '' ? (
                <Picture src={`${product.prodPicture}?w=100`} className='' />
              ) : (
                <img src="/img/no-picture.png" />
              )}
            </div>
          )}

        </td>
        <td className='ref text-center'>
          <div >
            {product.prodRef}
          </div>
        </td>
        {product.prodCatalogue.toLowerCase() != 'kit' ?
          <>
            <td className='constructor'>
              {product.prodMarque}<br />
              <small>{product.prodModele}</small>
            </td>
            <td className='nowrap years text-center'>{product.prodAnnee}
              {/* <br /> {product.availableYears} */}
            </td>
          </>
          :
          <>
            <td className='memo'>
              {product.prodTitre.trim() ? product.prodTitre : product.prodMemo}
            </td>
          </>
        }
        {product.prodCatalogue.toLowerCase() != 'kit' && <td className='nowrap type text-center'>{product.prodType}</td>}
        <td className='nowrap price text-center'>
          {product.prodPrix !== '0' ? formatPrice(product.prodPrix) : '---'}
        </td>
        <td className='nowrap stock text-center pt-2'>
          <ProdCodeStock codeStock={product.prodCodeStock} />
        </td>
        <td className='text-center open big'>
          <IoOpenOutline className=" text-green" />
        </td>
      </tr>)
  }
}
export default ProductListItem;
